<template>
  <R3Title title="Reporting ❯ GPS"></R3Title> <!-- unicode U+276F : ❯ -->
  
  <div class="pageContent">
    <DxMap
      :zoom="7"
      :height="600"
      v-model:type="mapType"
      v-model:markers="markers"
      
      center="52.2668, -113.802"
      width="100%"
      provider="bing"
      api-key="Aq3LKP2BOmzWY47TZoT1YdieypN_rB6RY9FqBfx-MDCKjvvWBbT68R51xwbL-AqC"
    />
    <!-- provider="bing" -->
    <!-- :api-key="apiKey" -->
    <!-- v-model:type="satellite" -->
    <!-- v-model:type="roadmap" -->
    <!-- v-model:marker-icon-src="markersIcon" -->
  </div>
  
</template>

<script>

import R3Title from "@/components/base/page-title.vue";
import DxMap from 'devextreme-vue/map';

export default {
  components: {
    R3Title,
    DxMap
  },
  created() {
    this.loadPageData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  data() {
    return {
      mapType:"hybrid",
      mapTypes: [{
        key: 'roadmap',
        name: 'Road Map',
      }, {
        key: 'satellite',
        name: 'Satellite (Photographic) Map',
      }, {
        key: 'hybrid',
        name: 'Hybrid Map',
      }],
    };
  },
  computed: { 
    markers(){
      
      let conts = this.$store.getters["containers/getContainers"];
      if (!conts)
      { return [] ;}
      let marks = [];
      for (let i=0; i<conts.length;i++ )
      {
        if (conts[i].latitude > 0){
          let mark = {
            location: [conts[i].latitude, conts[i].longitude],
            tooltip: { text: conts[i].containerName}}
          marks.push(mark);
        }
        if (marks.length>100)
          i = 999999; //as test only show the first 100 values
      }
      return marks;
    }
  },
  methods: {
    setDimensions() {
      const h = document.documentElement.clientHeight;
      this.datagridHeight= (h-215) + 'px';
    },
    async loadPageData() {
      await this.$store.dispatch("containers/loadContainers");
    },
  },
};

</script>

<style scoped>
  .pageContent {
    /* max-height: 40vh; */
    /* overflow: scroll; */
  }
  .ph { /* page header */
    height: 50px;
  } 
  img {
    width: 80px;  
    height: 80px;
  } 
  p {
    text-align: left;  
    margin-left:4em; 
    margin-top:0em;
    font-weight: 300;
  }
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
  .prop{
    margin: 10px 60px 0 60px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  .mininote {
    text-align: left;  
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
</style>
