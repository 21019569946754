export  default {
    getOperators(state) {
        return state.operators;
    },
    getCurrentOperator(state) {
        return state.currentOperator;
    },
    getOperatorsAreas(state) {
        if (state.operatorsAreas !== undefined && state.operatorsAreas != null)
            return state.operatorsAreas;
        else
            return [];
    },

};