export  default {
    devices(state) {
        return state.devices;
    },
    hasDevices(state) {
        return state.devices && state.devices.length > 0;
    },
    getActiveDevices(state) {
        console.log("STATE ACTIVE", state.activeDevices)
        var active=[]
        var deviceMaster = state.activeDevices
        console.log("DEVICE MASTER", deviceMaster)
        for (let i = 0; i < deviceMaster.length; i++) {
            // console.log(deviceMaster[i])
            
            const device = {
                surface: deviceMaster[i].containerName,
                alias: deviceMaster[i].alias,
                downholeLSD:deviceMaster[i]['downhole lsd'],
                imei: deviceMaster[i].imei,
                manufacturer: deviceMaster[i].manufacturer,
                containerType: deviceMaster[i].containerType,
                operator: deviceMaster[i].operator,
                area: deviceMaster[i].area,
                office: deviceMaster[i].office,
                district: deviceMaster[i].district,
                product: deviceMaster[i].product,
                satellite: deviceMaster[i].satellite,
                latitude: deviceMaster[i].latitude,
                longitude: deviceMaster[i].longitude,
            };
            // console.log(device)
            active.push(device);

        }
        console.log("ACTIVE", active)
        return active;
    },
};