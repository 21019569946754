<template>
  <div class="content-block dx-card" >
    <h2 class="content-block">Property Lists</h2>
    <DxToolbar class="menubar">
      <DxItem
          :options="cancelButtonOptions"
          location="before"
          widget="dxButton"
        />
      <DxItem
          :options="saveButtonOptions"
          location="before"
          widget="dxButton"
        />
    </DxToolbar>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <!-- <form action="/url" method="GET">
        <input id="data" type="text">
    </form> -->
    <DxForm id="form" 
        :form-data="propertyList" 
        :label-mode="outside" 
        :read-only="false" 
        :show-colon-after-label="true"
        :label-location="top" 
        :col-count="1" 
        :min-col-width="300" 
        :width="null"
      >
      <!-- :items="['propListId', 'areaName', 'regionId']" -->
      <DxSimpleItem data-field="propListId"  :editor-options="{readOnly: true}" />
      <DxSimpleItem data-field="propertyId" editor-type="dxSelectBox" :editor-options="propertyFieldOptions()" />
      <DxSimpleItem data-field="listValue" />
      <DxSimpleItem data-field="propertyExternal" />
    </DxForm>
  </div>
  <DxLoadPanel
    v-model:visible="loadingVisible"
    :message="Loading"
    :show-indicator="true"
    :show-pane="true"
    :shading="true"
    :hide-on-outside-click="false"
    :on-shown="onLoadPanelShown"
    :on-hidden="onLoadPanelHidden"
    shading-color="rgba(0,0,0,0.4)"
  />
  <DxToast
      v-model:visible="toastVisible"
      v-model:message="toastMessage"
      v-model:type="toastType"
      position="bottom center"
    />
</template>

<script>

import DxForm, { DxSimpleItem } from "devextreme-vue/form";
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
// import notify from 'devextreme/ui/notify';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxToast } from 'devextreme-vue/toast';

export default {
  components: {
    DxForm, 
    DxSimpleItem, 
    DxToolbar,
    DxItem,
    DxLoadPanel,
    DxToast
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      loadingVisible: false,
      toastVisible: false,
      toastMessage: '',
      toastType: 'info',
      toastPosition: {
          at: "bottom",
          my: "top",
          of: "#pwreset" },
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          //this.refreshData();
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          this.loadingVisible = true;
          if (this.propertyList.propListId==0)
          {
            this.$store.dispatch('properties/addPropertyList', this.propertyList);
            this.$emit('recordUpdated');
          }
          else
          {
            this.$store.dispatch('properties/updatePropertyList', this.propertyList);
            this.$emit('recordUpdated');
          }
          const rc = this.$store.getters["properties/getResponseCode"];
          
          if (rc >= 200 && rc <= 299)
          {
            this.toastType = 'success';
            this.toastMessage = 'Record saved (' + rc + ').';
            this.toastVisible = true;
          }
          else{
            this.toastType = 'error';
            this.toastMessage = 'Error saving record (' + rc + ').';
            this.toastVisible = true; 
          }
      
          this.loadingVisible = false;
        },
      },
    };
  },
  computed: {
    currentRecordId() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1); 
    },
    // properties() {
    //   return this.$store.getters["properties/getProperties"];
    // },
    properties() {
      var propsWithType = this.$store.getters["properties/getProperties"]
      if (propsWithType)
      {
        propsWithType = propsWithType.map(x=> {
          x['containerTypeName'] = this.containerTypes.filter(y=>y.containerTypeId==x.containerTypeId)[0].containerTypeName  
          return x  
        })   
        return propsWithType
      }
      return null
    },
    productProperties() {
      if (this.properties)
      {
        var productProps = this.properties.filter(x=>x.propertyName.includes('Product'))
        productProps = productProps.map(x=> {
          x['propertyName'] = x.containerTypeName + ' - ' + x.propertyName 
          return x
        })
        return productProps
      }
      return null
    },
    propertyList() {
      return this.$store.getters["properties/getCurrentPropertyList"];
    },
    containerTypes() {
      return this.$store.getters["containerTypes/getContainerTypes"];
    },
  },
  methods: {
    onLoadPanelShown() {
      setTimeout(() => { this.loadingVisible = false; }, 10000);
    },
    onLoadPanelHidden() {
      // 
    },
    propertyFieldOptions() {
      return {
        // items: this.properties,
        items: this.productProperties,
        valueExpr: "propertyId",
        displayExpr: "propertyName",
        searchEnabled:"true"

      }
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      this.loadingVisible = true;
      try {
        await this.$store.dispatch("containerTypes/loadContainerTypes", {forceRefresh: refresh});
        await this.$store.dispatch("properties/loadPropertyList", { id: this.currentRecordId });
        await this.$store.dispatch("properties/loadProperties", {forceRefresh: refresh});
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
      this.loadingVisible = false;      
    },
  },
};
</script>

<style scoped>
  .menubar{
    padding: 0 0 0 40px ;
  }
</style>
