import L from 'leaflet';

// Base shadow URL remains constant
const shadowUrl = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/marker-shadow.png';

// Define marker colors for different states
const markerColors = {
  critical: 'red',
  warning: 'orange',
  normal: 'green'
};

// Create icon URLs for different states using marker-icon CDN
const createIconUrl = (color) =>
  `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`;

// Cache icons to prevent recreation
const iconCache = new Map();

export const getContainerIcon = (level) => {
  try {
    let color;
    if (level === undefined || level === null) {
      console.warn('Level is undefined, defaulting to normal');
      color = markerColors.normal;
    } else if (level <= 20) {
      color = markerColors.critical;
    } else if (level <= 40) {
      color = markerColors.warning;
    } else {
      color = markerColors.normal;
    }

    if (iconCache.has(color)) {
      return iconCache.get(color);
    }

    const icon = L.icon({
      iconUrl: createIconUrl(color),
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    iconCache.set(color, icon);
    return icon;
  } catch (error) {
    console.error('Error creating map icon:', error);
    return L.icon({
      iconUrl: createIconUrl(markerColors.normal),
      shadowUrl
    });
  }
};

export const getMarkerIcon = (volume) => {
  let color
  if (volume <= 20) {
    color = markerColors.critical
  } else if (volume <= 40) {
    color = markerColors.warning
  } else {
    color = markerColors.normal
  }

  return L.icon({
    iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  })
}

export const getMarkerIconThreshold = (container) => {
  // ❌⚠️✔️
  let color
  if (container.containerStatus =="❌") {
    color = markerColors.critical
  } else if (container.containerStatus =="⚠️") {
    color = markerColors.warning
  } else {
    color = markerColors.normal
  }
  return L.icon({
    iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  })
}

export const getContainerStatusClass = (level) => {
  if (level <= 20) return 'critical';
  if (level <= 40) return 'warning';
  return 'normal';
};

export const getLevelColor = (level) => {
  if (level <= 20) return '#ff4444'; 
  if (level <= 40) return '#ffa033';  
  if (level <= 60) return '#ffe234'; 
  if (level <= 80) return '#81d04f';  
  return '#4caf50';                  
};
