<!-- ControlPanel.vue -->
<template>
  <div class="control-panel">
    <div class="filters">
      <div class="filter-item">
        <label>Operator</label>
        <DxSelectBox
          :data-source="operators"
          :show-clear-button="true"
          :search-enabled="true"
          @value-changed="onOperatorChanged"
          placeholder="Select Operator"
          styling-mode="outlined"
        />
      </div>

      <div class="filter-item">
        <label>Area</label>
        <DxSelectBox
          :data-source="areas"
          :show-clear-button="true"
          :search-enabled="true"
          @value-changed="onAreaChanged"
          placeholder="Select Area"
          styling-mode="outlined"
        />
      </div>

      <div class="filter-item">
        <label>Products</label>
        <DxTagBox
          :items="products"
          :search-enabled="true"
          :input-attr="{ 'aria-label': 'Product' }"
          :show-selection-controls="true"
          apply-value-mode="useButtons"
          show-drop-down-button="true"
          styling-mode="outlined"
          max-displayed-tags="0"
          multiline="false"
          :wrap-item-text="true"
          @value-changed="onProductsChanged"
        />
        <!-- apply-value-mode="useButtons / instantly" -->
      </div>

      <!-- <div class="filter-item">
        <label>Product</label>
        <DxSelectBox
          :data-source="products"
          :show-clear-button="true"
          :search-enabled="true"
          @value-changed="onProductChanged"
          placeholder="Select Product"
          styling-mode="outlined"
        />
      </div> -->

      <div class="filter-item">
        <label>Volume Range</label>
        <div class="filter-range-select">
          <DxDropDownButton
            :items="filterVolType" 
            :text="filterVolTypeSelected"
            :show-arrow-icon="false"
            @selection-changed = "volFilterTypeChange"
          />
          <!-- @item-click = "onItemClick" -->
          <!-- :use-item-text-as-title="true" -->
          <!-- :text="filterVolTypeSelected" -->
        </div> 
        <div class="filter-textbox">
          <DxNumberBox
            :width="140"
            :value="1"
            :step="0.01"
            :min="0"
            :max="1"
            format="#0%"
            :show-spin-buttons="true"
            styling-mode="outlined"
            @value-changed="onVolumeRangeChanged"
          />
        </div> 
      </div>
      <div class="filter-item">
        <label>DTE Range</label>
        <div class="filter-range-select">
          <DxDropDownButton
            :items="filterVolType" 
            :text="filterDTETypeSelected"
            :show-arrow-icon="false"
            @selection-changed = "dteFilterTypeChange"
          />
        </div> 
        <div class="filter-textbox">
          <DxNumberBox
            :width="140"
            value="10000"
            :step="1"
            :min="0"
            :max="10000"
            :show-spin-buttons="true"
            styling-mode="outlined"
            @value-changed="onDTERangeChanged"
          />
        </div> 
      </div>

      <!-- <div class="filter-item">
        <label>Volume Range</label>
        <DxRangeSlider
          :min="0"
          :max="100"
          :value="volumeRange"

          @value-changed="onVolumeRangeChanged"
        />
      </div> -->

    </div>

    <div class="stats">
      <div class="stat-item">
        <span class="stat-label">Total Containers:</span>
        <span class="stat-value">{{ totalContainers }}</span>
      </div>
      <div class="stat-item">
        <span class="stat-label">Filtered:</span>
        <span class="stat-value">{{ filteredCount }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits, defineProps, watch  } from 'vue'
import { useStore } from 'vuex'
import { DxSelectBox, DxRangeSlider } from 'devextreme-vue'
import { DxDropDownButton } from 'devextreme-vue/drop-down-button';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxTagBox } from 'devextreme-vue/tag-box';

const props = defineProps({
  filteredCount: {
    type: Number,
    default: 0
  },
  filteredContainers: Array,
})

const emit = defineEmits(['operator-changed', 'area-changed', 'product-changed', 'volume-range-changed'])
const store = useStore()
const volumeRange = ref([0, 100])
const filteredCount = computed(() => props.filteredCount || 0)
const filteredContainers = computed(() => props.filteredContainers || "")

const containers = computed(() => store.getters['containers/getContainersFlatProperties_ViewOperator'])
// const filterVolType = ['<=', '=', '>='];
const filterVolType = ['<=', '>='];
const filterVolTypeSelected = ref('<=')
const filterDTETypeSelected = ref('<=')

const operators = computed(() => {
  // const values = containers.value?.map(c => c.operator) || [] // all containers
  const values = filteredContainers.value?.map(c => c.operator) || [] // filtered container
  return [...new Set(values)].filter(Boolean).sort()
})

const areas = computed(() => {
  // const values = containers.value?.map(c => c.area) || []
  const values = filteredContainers.value?.map(c => c.area) || []
  return [...new Set(values)].filter(Boolean).sort()
})

const products = computed(() => {
  // const values = containers.value?.map(c => {
  //   const productProp = c.properties?.find(p => p.propertyName === 'Product')
  //   return productProp?.propValue
  // }) || []
  const values = filteredContainers.value?.map(c => c.Product) || []
  return [...new Set(values)].filter(Boolean).sort()
})

watch(() => props.filteredCount, (newCount) => {
  // console.log('Filtered count updated:', newCount)
})

watch(volumeRange, (newRange) => {
  // console.log('Volume range updated:', newRange)
  emit('volume-range-changed', newRange)
})
const totalContainers = computed(() => containers.value?.length || 0)

function volFilterTypeChange(e){
  filterVolTypeSelected.value = e.item
}

function dteFilterTypeChange(e){
  filterDTETypeSelected.value = e.item
}

const onOperatorChanged = ({ value }) => {
  emit('operator-changed', value || '')
}

const onAreaChanged = ({ value }) => {
  emit('area-changed', value || '')
}

const onProductsChanged = ({ value }) => {
  emit('products-changed', value || '')
}

const onProductChanged = ({ value }) => {
  emit('product-changed', value || '')
}

const onVolumeRangeChanged = ({ value }) => {
  // emit('volume-range-changed', value || [0, 100])
  const percVal = value * 100
  if (filterVolTypeSelected.value == '<=') 
    emit('volume-range-changed', [0,percVal] || [0, 100])
  // if (filterVolTypeSelected.value == '=') 
  //   emit('volume-range-changed', [percVal,percVal] || [0, 100])
  if (filterVolTypeSelected.value == '>=') 
    emit('volume-range-changed', [percVal,100] || [0, 100])
}

const onDTERangeChanged = ({ value }) => {
  // emit('volume-range-changed', value || [0, 100])
  
  if (filterDTETypeSelected.value == '<=') 
    emit('dte-range-changed', [0,value] || [0, 100])
  // if (filterDTETypeSelected.value == '=') 
  //   emit('dte-range-changed', [value,value] || [0, 100])
  if (filterDTETypeSelected.value == '>=') 
    emit('dte-range-changed', [value,100] || [0, 100])
}
</script>

<style lang="scss" scoped>
@import "@/themes/generated/variables.base.scss";
@import "@/themes/generated/variables.additional.scss";
.control-panel {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
}

.filters {
  display: flex;
  gap: 20px;
  flex: 1;
}

.filter-item {
  flex: 1;
  min-width: 175px;

  label {
    display: block;
    margin-bottom: 8px;
    color: rgba($base-text-color, 0.7);
    font-size: 14px;
  }
}

.filter-range-select{
  display:inline-block;
  margin-right:10px; 
}
.filter-textbox{
  display:inline-block;
  margin-right:10px; 
}
#element2 {display:inline-block; width:200px; background-color:red;} 

.stats {
  display: flex;
  gap: 20px;
}

.stat-item {
  text-align: center;
  
  .stat-label {
    display: block;
    color: rgba($base-text-color, 0.7);
    font-size: 12px;
  }
  
  .stat-value {
    font-size: 24px;
    font-weight: 500;
    color: $base-accent;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .control-panel {
    flex-direction: column;
  }

  .filters {
    flex-direction: column;
  }
}
</style>


