<template>
  <!-- <div> -->
    <!-- <h2 class="content-block">{{title}}</h2>  -->
    <h2 class="content-block subheader">{{title}}</h2> 
  <!-- </div>   -->
</template>

<script>
export default {
  props: {
    title: String,
  },
  data() {
    return {
      
    };
  },
};
</script>

<style scoped>
  .subheader {
    font-size: 18px;
    font-weight: 300;
    color: rgb(255,255,255,.6);
    margin-bottom: 6px;
    z-index: 20;
  }
</style>
