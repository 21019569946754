import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import store from './store/index.js';
import VueMobileDetection from "vue-mobile-detection";

import App from "./App";
import appInfo from "./app-info";

// Add Leaflet imports
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueMobileDetection);

app.config.globalProperties.$mapConfig = {
  apiKey: process.env.VUE_APP_MAPBOX_TOKEN || 'your-default-token',
  defaultCenter: [53.5461, -113.4937],
  defaultZoom: 5,
  maxZoom: 18,
  minZoom: 3
};1

app.config.globalProperties.$appInfo = appInfo;
app.mount('#app');

window.addEventListener('error', function(e) {
  if (e.target.tagName === 'LINK' && e.target.href.includes('mapbox')) {
    console.error('Map resource failed to load:', e);
  }
});
