export default {
    getApiUrl(state) {
        return state.apiUrl;
    },
    getMapboxToken: (state) => {
    return state.mapbox.accessToken;
  },
  
  getMapDefaults: (state) => {
    return {
      center: state.mapbox.defaultCenter,
      zoom: state.mapbox.defaultZoom
    };
  }
};