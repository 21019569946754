<template>
  <div class="content-block dx-card" >
    <h2 class="content-block">District Details</h2>
    <DxToolbar class="menubar">
      <DxItem
          :options="cancelButtonOptions"
          location="before"
          widget="dxButton"
        />
      <DxItem
          :options="saveButtonOptions"
          location="before"
          widget="dxButton"
        />
        <DxItem
          :options="deleteButtonOptions"
          location="before"
          widget="dxButton"
        />
    </DxToolbar>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxForm id="form" 
        :form-data="districts" 
        :label-mode="outside" 
        :read-only="false" 
        :show-colon-after-label="true"
        :label-location="top" 
        :col-count="1" 
        :min-col-width="300" 
        :width="null"
        :items="['districtId', 'districtName']">
      >
      <DxSimpleItem data-field="districtId"  :editor-options="{readOnly: true}" />
      <DxSimpleItem data-field="districtName" />
    </DxForm>
  </div>
  <DxLoadPanel
    v-model:visible="loadingVisible"
    :message="Loading"
    :show-indicator="true"
    :show-pane="true"
    :shading="true"
    :hide-on-outside-click="false"
    :on-shown="onLoadPanelShown"
    :on-hidden="onLoadPanelHidden"
    shading-color="rgba(0,0,0,0.4)"
  />
  <DxToast
      v-model:visible="toastVisible"
      v-model:message="toastMessage"
      v-model:type="toastType"
      position="bottom center"
    />
</template>

<script>

import DxForm, { DxSimpleItem } from "devextreme-vue/form";
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
// import notify from 'devextreme/ui/notify';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxToast } from 'devextreme-vue/toast';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

export default {
  components: {
    DxForm, 
    DxSimpleItem, 
    DxToolbar,
    DxItem,
    DxLoadPanel,
    DxToast
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      loadingVisible: false,
      toastVisible: false,
      toastMessage: '',
      toastType: 'info',
      toastPosition: {
          at: "bottom",
          my: "top",
          of: "#pwreset" },
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          //this.refreshData();
          this.$router.go(-1);
        },
      },
      deleteButtonOptions: {
        icon: 'close',
        onClick: () => {
          let result = confirm("<b>Are you sure you want to delete this district?</b>" + 
            "<p>Name: " + this.districts.districtName,
            "Delete?");
          result.then((dialogResult) => {this.delete(dialogResult);});
        },
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          this.loadingVisible = true;
          if (this.districts.districtId==0)
          {
            this.$store.dispatch('districts/addDistrict', this.districts);
            this.$emit('recordUpdated');
          }
          else
          {
            this.$store.dispatch('districts/updateDistrict', this.districts);
            this.$emit('recordUpdated');
          }
          const rc = this.$store.getters["districts/getResponseCode"];
          
          if (rc >= 200 && rc <= 299)
          {
            this.toastType = 'success';
            this.toastMessage = 'Record saved (' + rc + ').';
            this.toastVisible = true;
          }
          else{
            this.toastType = 'error';
            this.toastMessage = 'Error saving record (' + rc + ').';
            this.toastVisible = true; 
          }
      
          this.loadingVisible = false;
        },
      },
    };
  },
  computed: {
    currentRecordId() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1); 
    },
    districts() {
      return this.$store.getters["districts/getCurrentDistrict"];
    },
  },
  methods: {
    onLoadPanelShown() {
      setTimeout(() => { this.loadingVisible = false; }, 10000);
    },
    onLoadPanelHidden() {
      // 
    },
    popNotification(msg, type){
      //types ['error', 'info', 'success', 'warning']
      const position = 'center';
      const direction = 'down-stack';
      notify(
        {
          message: msg,
          type: type
        },
        { 
          position, 
          direction 
        }
      );
    },
    async delete(confirmed){
      if (!confirmed)
      { 
        this.popNotification('Delete record cancelled.','warning');
        return; 
      }
      this.popNotification("Record deleted. ", 'success');

      await this.$store.dispatch('districts/deleteDistrict', {districtId: this.districts.districtId});
      this.$emit('recordUpdated');
      // this.result = this.$store.getters["utils/getDecommissionSiteResults"];
      // if (this.result.recordsAffected==-1)
      //   { this.popNotification("Site decommissioned successfully. " + Date().toLocaleString() + "\n" + this.result.message,'success'); }
      
      // else
      //   { this.popNotification("ERROR: Site not decommissioned. " + Date().toLocaleString() + "\n" + this.result.message,'error'); }
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      this.loadingVisible = true;
      try {
        await this.$store.dispatch("districts/loadDistrict", { id: this.currentRecordId });
        
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
      this.loadingVisible = false;      
    },
  },
};
</script>

<style scoped>
  .menubar{
    padding: 0 0 0 40px ;
  }
</style>
