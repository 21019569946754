<template>
  <div class="ph" id ="header">
    <h2 class="content-block">Reporting ❯ Tank Status</h2> <!-- unicode U+276F : ❯ -->

    <DxLoadPanel
    :position="{ of: '#dataDiv' }"
    v-model:visible="loadingVisible"
    :message="Loading"
    :show-indicator="true"
    :show-pane="true"
    :shading="true"
    :hide-on-outside-click="false"
    :on-shown="onShown"
    :on-hidden="onHidden"
    shading-color="rgba(0,0,0,0.4)"
    />

  </div>
  <div class="divGroup" id ="dataDiv">
    <DxSelectBox
      :data-source="operators"
      :show-clear-button="true"
      :search-enabled="true"
      @value-changed="onOperatorChanged"
      placeholder="Operator"
    />
    <br>
    <DxSelectBox
      :data-source="filteredAreas"
      :show-clear-button="true"
      :search-enabled="true"
      @value-changed="onAreaChanged"
      placeholder="Area"
    />
    <br>
    <DxSelectBox
      :data-source="products"
      :show-clear-button="true"
      :search-enabled="true"
      @value-changed="onProductChanged"
      placeholder="Product"
    />
    <br>
    <br>
    <div class="mininote">Scroll horizontally to view extra fields</div>
    <div class="mininote">Rows: {{ rowCount }} </div>
    <DxDataGrid
      :data-source="containers"
      :key-expr="containerId"
      :hover-state-enabled="true"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      :columns-auto-width="false"
      :column-min-width="50"
      :allow-column-resizing="true"
      :column-resizing-mode="currentMode"
      :allow-column-reordering="true"
      no-data-text="No data"
      @row-click="dataRowToggle"
      @initialized="saveGridInstance"
      @cell-prepared="onDataGridCellPrepared"
      @content-ready="dataReady"
      :style="{ 'max-height': datagridHeight }"
    >
    <DxLoadPanel :enabled="true" />
    <DxScrolling mode="standard" />     <!-- mode="virtual" -->
    <DxColumnFixing :enabled="true" />
    <DxPager
      :show-info="true"
      :show-page-size-selector="true"
      :allowed-page-sizes="[20, 50, 100]"
      :show-navigation-buttons="true"
    />
    
    <DxColumn id = "stat" data-field="containerStatus" fixed="true" caption="" min-width="5px" width="5px" alignment="left" :allow-resizing="false" :allow-filtering="true" :show-in-column-chooser="false" :allow-reordering="false"/>            
    <DxColumn data-field="containerName" caption="Name" :allow-reordering="true" min-width="150"/>
    <!-- <DxColumn data-field="properties[18].propValue" caption="Downhole LSD" alignment='left' min-width="150" />       -->
    <DxColumn data-field="Downhole LSD" caption="Downhole LSD" alignment='left' min-width="150" />      
    <DxColumn data-field="serialnum" caption="Device" :visible="false" min-width="125"/>
    <!-- <DxColumn data-field="properties[11].propValue" caption="Product" alignment='left' min-width="100"/>   -->
    <DxColumn data-field="Product" caption="Product" alignment='left' min-width="100"/>  
    </DxDataGrid>
  
  </div>
  <div class="divGroup" id ="containerInfo">
    <!-- operator, area, name, downhole, location, product -->
    <!-- <div class="pheader">ID</div> -->
    <!-- <div class="pval">{{currentContainer.containerId}}</div> -->
    <div class="pheader">Name</div>
    <div class="pval">{{this.getContainerVal(this.currentContainer.containerName)}}</div>
    <div class="pheader">Downhole LSD</div>
    <div class="pval">{{this.getPropertyVal('Downhole LSD')}}</div>
    <div class="pheader">Operator</div>
    <div class="pval">{{this.getContainerVal(this.currentContainer.operator)}}</div>
    <div class="pheader">Area</div>
    <div class="pval">{{this.getContainerVal(this.currentContainer.area)}}</div>
    <div class="pheader">Device</div>
    <div class="pval">{{this.getContainerVal(this.currentContainer.serialnum)}}</div>
    <div class="pheader">Product</div>
    <div class="pval">{{this.getPropertyVal('Product')}}</div>
    <div class="pheader">Last Communication</div>
    <div class="pval">{{this.getPropertyVal('Last Device Communication')}}</div>
  </div>

  <div class="divGroup" id ="volumeInfo">
    <!-- <div class="floater" style="height: 100px"> -->
      <!-- <DxChart
        id="chart"
        :data-source="dataSource"
      >
        <DxLegend visible="false" />
        <DxCommonSeriesSettings
          argument-field="tank"
          type="fullstackedbar"
        />
        <DxSeries
          value-field="vol"
          name="Volume"
        />
        <DxSeries
          value-field="cap"
          name="Capacity"
          color="grey"
        />
        
      </DxChart> -->
    <!-- </div> -->

    <!-- capacity, volume(cm/l/%),  -->
    <div class="pheader">Shape</div>
    <div class="pval">{{this.getPropertyVal('Shape')}}</div>
    <div class="pheader">Capacity</div>
    <div class="pval">{{this.getPropertyVal('Capacity')}}</div>
    <div class="pheader">Volume (L)</div>
    <div class="pval">{{this.getPropertyVal('Volume (L)')}}</div>
    <div class="pheader">Volume (cm)</div>
    <div class="pval">{{this.getPropertyVal('Volume (cm)')}}</div>
    <div class="pheader">Volume (%)</div>
    <div class="pval">{{this.getPropertyVal('Volume (%)')}}</div>
    <div class="pheader">Temperature</div>
    <div class="pval">{{this.getPropertyVal('Temperature')}}</div>
    
  </div>
  <div class="divGroup" id ="injectionInfo">
    <!-- injection, inject target, dte -->
    <div class="pheader">Injection</div>
    <div class="pval">{{this.getPropertyVal('Injection Avg')}}</div>
    <div class="pheader">Injection Target</div>
    <div class="pval">{{this.getPropertyVal('Injection Target')}}</div>
    <div class="pheader">Days To Empty</div>
    <div class="pval">{{this.getPropertyVal('DTE')}}</div>
    <div class="pheader">Heel Inventory</div>
    <div class="pval">{{this.getPropertyVal('Heel Inventory')}}</div>

  </div>
  <div class="divGroup" id ="history">
    <!-- graphs-->
    
    <div v-if="showHistory==0">
      <DxButton text="Load Volume History" type="default" hint="Volume History" @click="loadHistory()" width="100%" styling-mode="outlined" />
    </div>
    <div v-else>
      <DxChart :data-source="containerVolumeHistory">
        <!-- <DxZoomAndPan argument-axis="both" value-axis="none"/> -->
        <DxCommonSeriesSettings
          argument-field="historyDate"
          value-field="propValue"
          type="line"
          show-in-legend="false"
          color="#9090FF"
        />
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
          item-text-position="bottom"
          visible="false"
        />
        <DxSeriesTemplate name-field="containerName" visible="false" />
        <!-- <DxExport :enabled="true" /> -->
        <DxTitle text="Tank History">
          <DxSubtitle text="(Volume L)" />
        </DxTitle>
        <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="100"/>
        <DxValueAxis show-zero="true" />  
      </DxChart>
    </div>
  </div>
  
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxLookup,
  DxToolbar,
  DxItem,
  DxStateStoring,
  DxMasterDetail,
  DxPaging,
  DxPager,
  DxColumnFixing
} from "devextreme-vue/data-grid";
// import {  DxSimpleItem } from 'devextreme-vue/form';
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxButton } from 'devextreme-vue/button';
import DxBox from 'devextreme-vue/box';
import DxTextBox from "devextreme-vue/text-box";
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxCircularGauge, DxScale, DxGeometry, DxValueIndicator , DxRangeContainer, DxRange} from 'devextreme-vue/circular-gauge';
import DxChart, {
  DxCommonSeriesSettings,
  DxSeriesTemplate,
  DxExport,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxValueAxis,
  DxSeries,
  // DxZoomAndPan,
  // DxStrip
} from "devextreme-vue/chart";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxLoadPanel,
    // DxToolbar,
    // DxItem,
    DxButton,
    DxPager,
    DxColumnFixing,
    DxSelectBox,
    // DxTextBox,
    // DxSimpleItem,
    // DxCircularGauge,
    // DxScale,
    // DxGeometry,
    // DxValueIndicator,
    // DxRangeContainer,
    // DxRange,
    DxChart,
    DxCommonSeriesSettings,
    DxSeriesTemplate,
    // DxExport,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxValueAxis,
    // DxSeries,
    // DxZoomAndPan,
    // DxStrip,
  },
  created() {
    this.loadPageData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  data() {
    return {
      currentMode: 'widget',
      dataGridInstance: null,
      currentContainer: "",
      currentProperties: "p",
      loadingVisible: false,
      datagridHeight: '40vh',
      criteriaOperator: "",
      criteriaArea: "",
      criteriaProduct: "",
      criteriaStatus: "",
      operators: [],
      areas: [],
      products: [],
      showHistory: 0,
      rowCount: 0,

      // dataSource : [{
      //   tank: 'USA',
      //   vol: 59.8,
      //   cap: 41
      // }],
    };
  },
  computed: {
    containers() {
      let retArr = this.$store.getters["containers/getContainersStatus"];
      if (this.criteriaOperator != null && this.criteriaOperator != "")
      {
        retArr = retArr.filter((data) => data.operator == this.criteriaOperator);
      }
      if (this.criteriaArea != null && this.criteriaArea != "")
      {
        retArr = retArr.filter((data) => data.area == this.criteriaArea);
      }
      if (this.criteriaProduct != null && this.criteriaProduct != "")
      {
        retArr = retArr.filter((data) => data.Product == this.criteriaProduct);
      }
      return retArr;
    },
    filteredAreas() {
      if (this.criteriaOperator != null && this.criteriaOperator != "")
      {
        const retArr = this.$store.getters["containers/getContainersStatus"].filter((data) => data.operator == this.criteriaOperator);
        const propertyValues = retArr.map(obj => obj['area']);
        const uniqueValuesSet = new Set(propertyValues);
        return (Array.from(uniqueValuesSet)).sort();
      }
      else
      {
        return this.areas;
      }
    },
    properties(index){
      return this.containers[index].properties;
    },
    containerProperties(){
      var data = this.currentContainer.properties;
      return data;
    },
    containerVolumeHistory(){
      const currentHistoryType = 14;
      var data = this.$store.getters["containers/getContainerHistory"];
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);

      for (const key in data) {
        if (data[key].propertyName==="Volume (L)" && data[key].historyDate>=dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }
      return volumeHist;
    }, 
  },
  methods: {
    loadHistory(){
      if (this.currentContainer.containerId != null && this.currentContainer.containerId !== undefined && this.currentContainer.containerId > 0)
      {
        this.$store.dispatch("containers/loadContainerHistory", this.currentContainer.containerId);
        this.showHistory = 1;
      }
    },
    dataReady(){
      this.rowCount = this.dataGridInstance.totalCount();
    },
    customizeTooltip(pointInfo) {
      return {
        html: `
          <div>
            <div style="width: 100px;padding:5px;text-align: center;"><span style="font-size:18px;font-weight:bold;">${pointInfo.value} L</span></div>
            <div style="width: 100px;padding:5px;text-align: center;">${pointInfo.argumentText}</div>
          </div>`
      };
    },
    onDataGridCellPrepared(e) {
      if(e.rowType === "data" && e.column.dataField === "containerStatus") {
        // e.cellElement.style.color = "red";
        if (e.values[0]=='❌')
        { e.cellElement.bgColor="#8B0000"; }
        else if (e.values[0]=='⚠️')
        // { e.cellElement.bgColor="yellow";}
        { e.cellElement.bgColor="#DAA520";}
        else //✔️
        // { e.cellElement.bgColor="green";}
        { e.cellElement.bgColor="#006400";}
        // console.log(e);
        // e.value = "red";
        // e.text = "red";
        // e.displayValue = "red";
        // e.values[0]="Red" //this one
        // e.cellElement.bgColor="red";
      }
    },
    onOperatorChanged(e) {
        // console.log(e.previousValue);
        // console.log(e.value);
        this.criteriaOperator = e.value;
    },
    onAreaChanged(e) {
        this.criteriaArea = e.value;
    },
    onProductChanged(e) {
        this.criteriaProduct = e.value;
    },
    getMaxInjection(){
      if (this.getPropertyVal('Injection Target') !='-')
      {
        return this.getPropertyVal('Injection Target')*2;
      }
      return 0;
    },
    getInjection(){
      if (this.getPropertyVal('Injection Avg') !='-')
      {
        return this.getPropertyVal('Injection Avg');
      }
      return 0;
    },
    getPropertyVal(propName){
      let retVal="-";
      if (this.currentContainer.containerName !== undefined)
      {
        console.log("PROPERTIES",this.currentContainer.properties)
        const p = this.currentContainer.properties.filter((data) => data.propertyName==propName);
        // console.log(propName, p[0].propValue, p[0].propValue.length)
        if ((String(p[0].propValue)).length === undefined || (String(p[0].propValue)).length == 0)
        {
          retVal = '-';
        }
        else{
          retVal = p[0].propValue;
        }
      }
      return retVal;
    },
    getContainerVal(propVal){
      if (propVal !== undefined)
      {
        return propVal;
      }
      else
      {
        return '-';
      }
    },
    setDimensions() {
      const h = document.documentElement.clientHeight;
      const dgh = (h<800) ? (h-25) : (h*.5)
      // this.datagridHeight= (h*.5) + 'px';
      this.datagridHeight= dgh + 'px';
    },
    changeResizingMode(data) {
      this.currentMode = data.value;
    },
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
      this.dataGridInstance.columnOption(0, 'allowHeaderFiltering', false);
    },
    dataRowToggle(e){
      this.currentContainer = e.component.getSelectedRowKeys()[0];
      this.showHistory=0;
    },
    async loadPageData(refresh = false) {
      try {
        this.loadingVisible = true;
        await this.$store.dispatch("containers/loadContainersStatus", {forceRefresh: refresh,});
        const ctrs = this.$store.getters["containers/getContainersFlatProperties"]
        let propertyValues = ctrs.map(obj => obj['operator']);
        let uniqueValuesSet = new Set(propertyValues);
        this.operators = (Array.from(uniqueValuesSet)).sort();
        propertyValues = ctrs.map(obj => obj['area']);
        uniqueValuesSet = new Set(propertyValues);
        this.areas = (Array.from(uniqueValuesSet)).sort();
        propertyValues = ctrs.map(obj => obj['Product']);
        uniqueValuesSet = new Set(propertyValues);
        this.products = (Array.from(uniqueValuesSet)).sort();
        console.log ("ctrs", ctrs)
        console.log ("containers", this.containers)
        this.loadingVisible = false;
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }  
    },
  },
};

</script>

<style scoped>
  .liquidFillGaugeText { 
    font-family: Helvetica; 
    font-weight: bold; 
  }
  svg {
    margin-top: 3em; 
  }
  .ph { /* page header */
    /* height: 50px; */
    /* margin-bottom: 50px; */
    margin: 40px 0px;
  } 
  img {
    width: 80px;  
    height: 80px;
  } 
  .divGroup {
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    /* border-color: rgb(255, 100, 0); */
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.2); */
    /* text-align: center; */
  }
  .mininote {
    text-align: left;  
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
  p {
    text-align: left;  
    margin-left:4em; 
    margin-top:0em;
    font-weight: 300;
  }
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
  .prop{
    margin: 10px 60px 0 60px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  .pheader {
    font-size: 14px;
    font-weight: 300;
    color: rgb(255,255,255,.4);
    margin-bottom: 6px;
    z-index: 20;
  }
  .pval {
    font-size: 22px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .floater{
    position: absolute;
    /* top: 10px; */
    right: 0;
    z-index: 10;
  }
  
</style>
