<template>
  <DxDrawer
    opened-state-mode="shrink"
    position="right"
    reveal-mode="expand"
    v-model:opened="drawerOpen"
    :close-on-outside-click="false"
    template="list"
    >

    <template #list>
      <!-- <div class="divGroup"> -->
      <div >
        <div style="width: 45vw">
          <DxSelectBox
            :items = "this.noteTypes"
            value-expr = "noteTypeId"
            display-expr = "noteTypeName"
            :search-enabled="true"
            @initialized="setNoteTypeInstance"
            @value-changed="changeNoteType"
          />
          <hr style="height:1px;border:1px solid #444; "/>
          <div class="buttons">
            <DxButton class="saveBtn" icon="save" text="Save" @click="saveNote"/>
            <DxButton class="cancelBtn" text="Cancel" @click="closeNote"/>
          </div>
        </div>
      </div>
    </template>

    <div>
      <DxButton icon="add" location="after" @click="addNote" />
      <DxScrollView v-if="(this.contNotes && this.contNotes.length>0)" :width="auto" :height="400" direction="both">  <!-- 'horizontal' | 'vertical' -->
        <div class="divGroup">
          <div v-for="note in this.contNotes" :key="note.containerNoteId">
            <div class="noteHeader">{{note.noteDate}}</div> 
            <!-- <div class="noteHeader">{{note.noteTypeId}} </div>   -->
            <div class="noteHeader">{{ noteTypes.filter(x=>x.noteTypeId==note.noteTypeId)[0].noteTypeName}} </div>  
            <!-- <div class="noteHeaderButton"> -->
              <!-- <DxButton icon="edit" @click="openNote(note)"/> -->
            <!-- </div>   -->
            <!-- <div class="noteSub">{{note.userId}}</div>   -->
            <!-- <div class="noteSub">{{ users.filter(x=>x.userId==note.userId)[0].userName}}</div>   -->
            <div class="noteSub">{{mapUserName(note)}}</div>  
            <hr style="height:1px;border:1px solid #444;"/>
          </div>
        </div>
      </DxScrollView>
    </div>
  </DxDrawer>
</template>

<script>

import { DxScrollView } from "devextreme-vue/scroll-view";
import DxTextBox from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';
import { DxBox, DxItem } from 'devextreme-vue/box';
import DxButtonGroup from 'devextreme-vue/button-group';
import DxDrawer from 'devextreme-vue/drawer';
import { DxSelectBox } from 'devextreme-vue/select-box';

export default {
  components: {
    DxScrollView,
    // DxTextBox,
    DxButton,
    // DxBox,
    // DxItem,
    // DxButtonGroup,
    DxDrawer,
    DxSelectBox
  },
  props: ['containerId', 'container'],
  created() { 
    this.loadPageData()
  },
  data() {
    return {
      drawerOpen: false,
      selectedNote: "",
      // noteTypes:[],
      noteTypeInstance: "",
      newNoteTypeId: 0.
    };
  },
  watch: {
    async containerId (){
      console.log("WATCHER");
      this.loadPageData();
    },
  },
  computed: {
    contNotes(){
      return this.$store.getters["containers/getContainerNotes"];
    },
    users(){
      return this.$store.getters["users/getUsers"];
    },
    noteTypes(){
      return this.$store.getters["noteTypes/getNoteTypes"];
    }
  },
  methods: {
    mapUserName(note){
      let uName = this.users.filter(x=>x.userId==note.userId)[0].userName
      if (uName==undefined || uName == null){ 
        let uname=""; 
      }
      return uName;
    },  
    addNote(){
      this.noteTypeInstance.reset();
      this.selectedNote = "";
      // console.log("NEW NOTE", this.selectedNote);
      this.drawerOpen = true;
    },
    openNote(note){
      this.selectedNote = note;
      this.drawerOpen = true;
    },
    closeNote(){
      this.drawerOpen = false;
    },
    saveNote()
    {
      const newNote = {
        containerId: this.container.containerId,
        noteTypeId: this.newNoteTypeId,
        note: "",
      };
      this.$store.dispatch('containers/addContainerNote', newNote);
      this.closeNote();
    },
    setNoteTypeInstance(e){
      this.noteTypeInstance = e.component;
    },
    changeNoteType(e){
      this.newNoteTypeId = e.value;
    },
    async loadPageData(){      
      if (this.container.containerId != null && this.container.containerId !== undefined && this.container.containerId > 0)
      {
        await this.$store.dispatch("containers/clearContainerNotes");
        await this.$store.dispatch("noteTypes/loadNoteTypes");
        await this.$store.dispatch("users/loadUsers");
        await this.$store.dispatch("containers/loadContainerNotes", this.container.containerId);
      }
    },
  },
};

</script>

<style scoped>
  .divGroup {
    width: 90%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .noteHeader {
    display:inline-block;
    /* background:grey; */
    min-width: 40%;
    font-size: 18px;
    font-weight: 200;
    margin-left: 10px;
    /* margin-bottom: 10px; */
    color: rgb(255,255,255,.9);
  }
  .noteHeaderButton {
    display:inline-block;
    /* background:grey; */
    min-width: 10%;
    font-size: 18px;
    font-weight: 200;
    margin-left: 10px;
    /* margin-bottom: 10px; */
    color: rgb(255,255,255,.9);
  }
  .noteSub {
    /* display:inline-block; */
    font-size: 12px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    color: rgb(255,255,255,.5);
  }
  .note {
    font-size: 14px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 30px;
    color: rgb(255,255,255);
  }
  .hr {
    height:1px;
    border:1px solid #444;
  }
  .buttons {
    width: 200px;
    margin: 0 auto;
    display: inline;
  }
  .saveBtn {  
    color: black;  
    background-color: rgb(255, 115, 0);  
    /* margin: 0 20px 20px 0; */
    margin: 0  10px auto;
    width: 100px;
    display: inline-block;
    /* float: left; */
  } 
  .cancelBtn {  
    color: black;  
    background-color: rgb(255, 255, 255,0.3);  
    /* margin: 0 20px 20px 0; */
    margin: 0 auto;
    width: 100px;
    display: inline-block;
    /* float: right; */
    /* display: flex; */
    /* justify-content: flex-end; */
  } 
  .buttons {
    width: 100px;
    margin: 0 auto;
  }

</style>
