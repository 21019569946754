import common from '../common.js';

export default {
  async deleteArea(context, payload) {
    const newRequest = {
      areaId: payload.areaId,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/areas/${payload.areaId}`;
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    if (!response.ok) {
      const error = new Error('Failed to send request.');  
      throw error;
    }
    // context.commit('updateDistrict', payload);
  },
  async updateArea(context, payload) {

    const newRequest = {
      areaId: payload.areaId,
      regionId: payload.regionId,
      areaName: payload.areaName,
      officeId: payload.officeId
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/areas/${payload.areaId}`;
    //var url = `https://r3backendprd.azurewebsites.net/api/areas/${payload.areaId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    //const responseData = await response.json();

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');  
      throw error;
    }
    context.commit('updateArea', payload);
  },

  async addArea(context, payload) {
    const newRequest = {
      areaId: payload.areaId,
      regionId: payload.regionId,
      areaName: payload.areaName,
      areaExternal: "",
      officeId: payload.officeId,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/areas`;
    //var url = `https://r3backendprd.azurewebsites.net/api/areas`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }
    
    const responseJson = await response.json();

    payload.areaId = responseJson.areaId;
    context.commit('addArea', payload);
  },

  async loadAreas(context, payload ) 
  {
    const responseJson = await common.getRequest(context, payload, 'areas')
    const responseData = [];

    for (const key in responseJson) 
    {
      const area = {
        areaId: responseJson[key].areaId,
        regionId: responseJson[key].regionId,
        areaName: responseJson[key].areaName,
        officeId: responseJson[key].officeId,
        };
      responseData.push(area);        
    }
    context.commit('setAreas', responseData)
    
  },

  async loadArea(context, payload ) 
  {
    const id = payload.id;

    let area = {
      areaId: 0,
      regionId: 1,
      areaName: "",
      officeId: 0
    };

    if (id != 0)
    {
      const responseJson = await common.getRequest(context, payload, `areas/${id}`)
      area = {
        areaId: responseJson.areaId,
        regionId: responseJson.regionId,
        areaName: responseJson.areaName,
        officeId: responseJson.officeId,
        };
    }
    context.commit('setCurrentArea', area)
  },
};
