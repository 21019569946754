<template>
  <CustLogo></CustLogo>
  <div class="aligncenter">
    <!-- <img src="../../assets/purechemlogo_sm.png" /> -->
  </div>
  <!-- <h2 class="content-block">Home</h2> -->
  
  <DxResponsiveBox
      :screen-by-width="screen"
      single-column-screen="sm"
    >
      <DxRow :ratio="1"/>
      <DxRow :ratio="1"/>
      <DxRow :ratio="1"/>

      <!-- <DxCol :ratio="1" screen="lg"/> -->
      <DxCol :ratio="1"/>
      <DxCol :ratio="1"/>
      <DxCol :ratio="1"/>
      <DxCol :ratio="1"/>
      <DxCol :ratio="1"/>
      <DxCol :ratio="1"/>
      
      <DxItem>
        <DxLocation
          :row="0"
          :col="0"
          :colspan="2"
          screen="lg"
        />
        <DxLocation
          :row="0"
          :col="0"
          :colspan="2"
          screen="sm"
        />
        <div class="dx-card responsive-paddings">
          <h2 class="card-content">Devices</h2>
          <h1 v-if="this.containers" class="card-content">{{ this.containers.length }}</h1>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation
          :row="0"
          :col="2"
          :colspan="2"
          screen="lg"
        />
        <DxLocation
          :row="0"
          :col="0"
          :colspan="2"
          screen="sm"
        />
        <!-- <div class="dx-card responsive-paddings">
          <p>Middle Left Bar</p>
        </div> -->
        <div class="dx-card responsive-paddings">
          <h2 class="card-content">Statuses</h2>
          <DxPieChart
            id="pie"
            :data-source="tankCountByStatus"
            type="doughnut"
          >
            <DxSeries argument-field="region" showInLegend="false">
              <DxLabel
                :visible="true"
              >
                <DxConnector :visible="true"/>
              </DxLabel>

            </DxSeries>
            <!-- <DxExport :enabled="false"/> -->
            <DxLegend
            :enabled="false"
              :margin="0"
              visible="false"
              horizontal-alignment="center"
              vertical-alignment="bottom"
            />
          </DxPieChart>
        </div>
      </DxItem>
      <DxItem>
        <DxLocation
          :row="0"
          :col="4"
          :colspan="2"
          screen="lg"
        />
        <DxLocation
          :row="0"
          :col="0"
          :colspan="2"
          screen="sm"
        />
        <!-- <div class="dx-card responsive-paddings">
          <p>Middle Middle</p>
        </div> -->
        <div class="dx-card responsive-paddings">
          <h2 class="card-content">Products</h2>
          <DxPieChart
            id="pie"
            :data-source="tankCountByProduct"
            type="doughnut"
          >
            <DxSeries argument-field="product" showInLegend="false">
              <DxLabel :visible="true">
                <DxConnector :visible="true"/>
              </DxLabel>
            </DxSeries>

            <DxLegend
              enabled="true"
              :margin="0"
              visible="false"
              horizontal-alignment="center"
              vertical-alignment="bottom"
            />
          </DxPieChart>
        </div>
      </DxItem>

      <DxItem>
        <DxLocation
          :row="1"
          :col="0"
          :colspan="3"
          screen="lg"
        />
        <DxLocation
          :row="1"
          :col="0"
          :colspan="3"
          screen="sm"
        />
        <div class="dx-card responsive-paddings">
        </div>
      </DxItem>
      <DxItem>
        <DxLocation
          :row="1"
          :col="3"
          :colspan="3"
          screen="lg"
        />
        <DxLocation
          :row="1"
          :col="0"
          :colspan="3"
          screen="sm"
        />
        <div class="dx-card responsive-paddings">
          <!-- <h2 class="card-content">Status By Area</h2>
          <DxDataGrid class="dx-card wide-card" 
            :data-source="areaInfo" 
            :selection="{ mode: 'single' }" 
            :show-borders="true"
            :columns-auto-width="true" 
            :column-min-width="50" 
            :hover-state-enabled="true" 
            :key-expr="areaId"
            :filter-builder="filterBuilder" 
            :filter-value="filterValue"
            :column-resizing-mode="nextColumn" 
            :allow-column-resizing="true" 
            style="max-height: 25vh"
          >
          <DxColumn data-field="name" caption="Name" />
          <DxColumn data-field="critical" caption="Critcal" />
          <DxColumn data-field="marginal" caption="Marginal" />
          <DxColumn data-field="good" caption="Good" />
          </DxDataGrid> -->
        </div>
      </DxItem>

      <DxItem>
        <DxLocation
          :row="1"
          :col="2"
          screen="lg"
        />
        <DxLocation
          :row="1"
          :col="2"
          screen="sm"
        />
        <div class="dx-card responsive-paddings">
          <!-- <p>Middle Right Bar</p> -->
        </div>
      </DxItem>

      <DxItem>
        <DxLocation
          :row="2"
          :col="0"
          screen="lg"
        />
        <DxLocation
          :row="2"
          :col="0"
          screen="sm"
        />
        <div class="dx-card responsive-paddings">
          <!-- <p>Bottom Left Bar</p> -->
        </div>
      </DxItem>
      <DxItem>
        <DxLocation
          :row="2"
          :col="1"
          screen="lg"
        />
        <DxLocation
          :row="2"
          :col="1"
          :colspan="2"
          screen="sm"
        />
        <div class="dx-card responsive-paddings">
          <!-- <p>Bottom Middle</p> -->
        </div>
      </DxItem>
      <DxItem>
        <DxLocation
          :row="2"
          :col="2"
          screen="lg"
        />
        <DxLocation
          :row="2"
          :col="2"
          screen="sm"
        />
        <div class="dx-card responsive-paddings">
          <!-- <p>Bottom Right Bar</p> -->
        </div>
      </DxItem>
      <DxItem>
        <DxLocation
          :row="2"
          :col="3"
          screen="lg"
        />
        <DxLocation
          :row="2"
          :col="3"
          screen="sm"
        />
        <div class="dx-card responsive-paddings">

        </div>
      </DxItem>
      <DxItem>
        <DxLocation
          :row="2"
          :col="4"
          screen="lg"
        />
        <DxLocation
          :row="2"
          :col="4"
          screen="sm"
        />
        <div class="dx-card responsive-paddings">

        </div>
      </DxItem>
      <DxItem>
        <DxLocation
          :row="2"
          :col="5"
          screen="lg"
        />
        <DxLocation
          :row="2"
          :col="5"
          screen="sm"
        />
        <div class="dx-card responsive-paddings">

        </div>
      </DxItem>
    </DxResponsiveBox>

</template>

<script>
import CustLogo from "../../components/customer/customer_logo.vue";
// import { useRouter, useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { DxResponsiveBox, DxItem, DxLocation, DxCol, DxRow } from 'devextreme-vue/responsive-box';
// import DxTreeMap, { DxTooltip } from 'devextreme-vue/tree-map';
import DxPieChart, {
  DxLegend,  
  DxSeries, DxLabel,  DxConnector,  
  // DxExport
} from 'devextreme-vue/pie-chart';
import {
  // DxDataGrid,
  // DxColumn,
  // DxHeaderFilter,
  // DxFilterRow,
  // DxScrolling,
  // DxColumnChooser,
  // DxLookup,
  // DxLoadPanel,
  // DxToolbar,
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxResponsiveBox, 
    DxItem,
    DxLocation, 
    DxCol, 
    DxRow,
    // DxTreeMap,
    // DxTooltip,
    DxPieChart, 
    DxLegend,  
    DxSeries,   DxLabel,  DxConnector,  
    // DxExport,
    // DxDataGrid,
    // DxColumn,
    // DxHeaderFilter,
    // DxFilterRow,
    // DxScrolling,
    // DxColumnChooser,
    // DxLookup,
    // DxLoadPanel,
    // DxToolbar,
    CustLogo,
  },
  data() {
    return {
      screen(width) {
        return (width < 700) ? 'sm' : 'lg';
      },
      menu: ["Reporting", "Master Data Entry", "User Profile"],
      router: useRouter(),
    };
  },
  created() {
    this.loadPageData();
  },
  computed: {
    containers() {
      // return this.$store.getters["containers/getContainersStatus"];
      return this.$store.getters["containers/getContainersFlatProperties"];
    },
    tankCountByStatus() {
      if (this.containers==undefined || this.containers==null)
      {
        return 0;
      }
      var statusCount = [
        {
          region: "Critical",
          val: this.containers.filter((x) => x.containerStatus == "❌").length,
        },
        {
          region: "Marginal",
          val: this.containers.filter((x) => x.containerStatus == "⚠️").length,
        },
        {
          region: "Good",
          val: this.containers.filter((x) => x.containerStatus == "✔️").length,
        },
      ];
      // console.log(this.containers[0].properties[10].propValue);
      return statusCount;
    },
    tankCountByProduct() {
      var productCount = [];
      var productList = [];
      var productsTotal = [];
      var otherCount = 0;
      var otherCutoff = 3;
      // getting a list of all the product names
      for (const key in this.containers) {
        var p = this.containers[key]['Product'];
        productsTotal.push(p);
        if (!productList.includes(p)) {
          productList.push(p);
        }
      }
      // counting the amount of each product
      for (const p in productList) {
        var pName = productList[p];
        var pCount = productsTotal.filter((x) => x == pName).length;
        if (pCount / productsTotal.length < otherCutoff / 100) {
          otherCount = otherCount + pCount;
        } else {
          productCount.push({ product: pName, val: pCount });
        }
      }
     // adding the "other" category
     if (otherCount > 0) {
        productCount.push({ product: "Other (<"+otherCutoff+"%)", val: otherCount });
      }      
      return productCount;
    },
  },
  methods:{
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("properties/loadProperties");
        await this.$store.dispatch("containers/loadContainersStatus", {forceRefresh: refresh,});
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
    changeRoute(route){
      useRouter.push(route);
      // router.push(route);
    },
    customizeTooltip(arg) {
      const { data } = arg.node;

      return {
        text: arg.node.isLeaf()
          ? `<span class="city">${data.name}</span> <br/>Devices: ${arg.valueText}`
          : null,
      };
    },
  }
};
</script>

<style scoped>
#app,
#page {
  height: 100%;
  min-height: 300px;
}
.aligncenter {
    text-align: center;
}
.card-content {
  margin: 0px 0px 0px 0px;
}
.critical-content {
  margin: 10px 0px 0px 0px;
  color: rgba(210, 0, 0, 0.8);
  font-size: 20px;
}
.marginal-content {
  margin: 10px 0px 0px 0px;
  color: rgba(210, 210, 0, 0.8);
  font-size: 20px;
}
.good-content {
  margin: 10px 0px 0px 0px;
  color: rgba(0, 180, 0, 0.8);
  font-size: 20px;
}
/* img {
    width : 100%;
    max-width:250px;
    height: auto; 
} */
/* 
.header {
  background-color: rgba(243, 158, 108, 0.8);
  text-align: center;
}

.content {
  background-color: rgba(245, 229, 166, 0.8);
}

.left-side-bar {
  background-color: rgba(148, 215, 199, 0.8);
}

.right-side-bar {
  background-color: rgba(119, 199, 231, 0.8);
}

.footer {
  background-color: rgba(123, 155, 207, 0.8);
} */

.item {
  height: 100%;
  /* height: 200px; */
}

#page p {
  font-size: 20px;
  padding-top: 10px;
  text-align: center;
}
</style>

<!-- 
<style lang="scss">
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
</style>
<style scoped>
img {
  max-height: 500px;
  max-width: 350px;
  height: 50%;
  width: 50%;
}
p {
  font-size: 16px;
}
dxButton,
a {
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  font: inherit;
  background-color: #3a0061;
  border: 1px solid #3a0061;
  color: white;
  cursor: pointer;
  border-radius: 30px;
  margin-right: 0.5rem;
  display: inline-block;
}

</style> -->
