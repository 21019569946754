<template>
  <div id="root">
    <div :class="cssClasses">
      <component
        :is="$route.meta.layout"
        :title="title"
        :is-x-small="screen.getScreenSizeInfo.isXSmall"
        :is-large="screen.getScreenSizeInfo.isLarge"
      >
      <div class="content">
        <router-view></router-view>
      </div>
        <template #footer>
          <app-footer />
        </template>
      </component>
    </div>
  </div>
</template>

<script>
import AppFooter from "./components/app-footer";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed
} from "vue";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
  };
}

export default {
  components: {
    AppFooter
  },
  setup() {
    const vm = getCurrentInstance();

    const title = vm.proxy.$appInfo.title;
    const screen = reactive({ getScreenSizeInfo: {} });
    screen.getScreenSizeInfo = getScreenSizeInfo();
    
    function screenSizeChanged () {
      screen.getScreenSizeInfo = getScreenSizeInfo();
    }

    onMounted(() => {
      subscribe(screenSizeChanged);
    });

    onBeforeUnmount(() => {
      unsubscribe(screenSizeChanged);
    });

    const cssClasses = computed(() => {
      return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
    });

    return {
      title,
      screen,
      cssClasses
    };
  }
};
</script>

<style lang="scss">
@import "@/themes/_variables.scss";
@import "@/themes/dx-styles.scss"; 

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}



.app {
  
  // display: flex;
  height: 100%;
  // width: 100%;
  // background-image: url('@/assets/honeycomb-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right top;  
  background-size: cover;
}

/* Add Leaflet map styles */
.leaflet-container {
  background: darken($base-bg, 5) !important;  // Match app theme
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: $base-bg;
  color: $base-text-color;
}

.leaflet-bar a {
  background: $base-bg;
  color: $base-text-color;
  border-color: rgba($base-text-color, 0.2);
  
  &:hover {
    background: lighten($base-bg, 10%);
  }
}

/* Ensure map container fills space properly */
.map-container {
  height: calc(100vh - #{$header-height} - #{$toolbar-height});
  width: 100%;
  
  @media (max-width: $screen-xs-max) {
    height: calc(100vh - #{$header-height-mobile} - #{$toolbar-height-mobile});
  }
}
</style>
