<template>
  <DxDrawer
    opened-state-mode="push"
    position="right"
    reveal-mode="expand"
    v-model:opened="drawerOpen"
    :close-on-outside-click="false"
    template="request"
    >

    <template #request>
        <div style="width: 560px">
          <div>
            <!-- <div class="propHeader">ID: {{ container.containerId }}</div> -->
            <div class="propHeader">Name: {{ container.containerName }}</div>
            <div class="propHeader">Downhole: {{ container['Downhole LSD'] }}</div>
            <div class="propHeader">Device: {{ container['serialnum'] }}</div>
            <!-- <div class="propHeader">Operator: {{ container['operator'] }}</div> -->
            <div class="propHeader">Volume: {{ container['Volume (%)'] }}% / {{ container['Volume (L)'] }}L / {{ container['Volume (cm)'] }}cm</div>
            <div class="propHeader">Product: {{ container['Product'] }}</div>
            <hr />
            
            <!-- new calibration level -->
            <div class="propHeader" v-if="['calibration'].includes(selectedRequest)">
              <DxTextBox id="level" :width="auto" label="Level Height (cm)" @value-changed="infoChanged"/>
            </div>
            <!-- new calibration date -->
            <div class="propHeader" v-if="['calibration'].includes(selectedRequest)">
              <DxDateBox id="levelDate" label="Date/Time" type="datetime" :max="Date.now()" @value-changed="infoChanged" />
            </div>
            <!-- product -->
            <div class="propHeader" v-if="['product'].includes(selectedRequest)">
              <DxTextBox id="product" :width="auto" label="New product" @value-changed="infoChanged" />
            </div>
            <!-- tank -->
            <div class="propHeader" v-if="['tank'].includes(selectedRequest)">
              <DxTextBox id="name" :width="auto" label="New Tank Name" @value-changed="infoChanged"/>
            </div>
            <!-- downhole -->
            <div class="propHeader" v-if="['tank'].includes(selectedRequest)">
              <DxTextBox id="downhole" :width="auto" label="New Downhole LSD" @value-changed="infoChanged"/>
            </div>
            <!-- capacity -->
            <div class="propHeader" v-if="['tank'].includes(selectedRequest)">
              <DxTextBox id="capacity" :width="auto" label="New Capacity" @value-changed="infoChanged"/>
            </div>

          </div>
          <hr/>
          <div class="buttons">
            <DxButton class="saveBtn" icon="email" text="Send" @click="sendRequest"/>
            <DxButton class="cancelBtn" text="Cancel" @click="closeRequest"/>
          </div>
        </div>
    </template>

    <hr/>
    <div >
      <DxBox>
        <DxItem :ratio="1">
          <DxButton class="requestBtn" text="Request Calibration" @click="openRequest('calibration')" />
        </DxItem>
        <DxItem :ratio="1">
          Request a sensor calibration comparing data from a physical measurement with calculated properties.
        </DxItem>
      </DxBox>
      <hr/>
      <DxBox>
        <DxItem :ratio="1">
          <DxButton class="requestBtn" text="Change Product" @click="openRequest('product')" />
        </DxItem>
        <DxItem :ratio="1">
          Request a product/chemical change.
        </DxItem>
      </DxBox>
      <hr/>
      <DxBox>
        <DxItem :ratio="1">
          <DxButton class="requestBtn" text="Change Tank" @click="openRequest('tank')" />
        </DxItem>
        <DxItem :ratio="1">
          Request a tank change (Size/Shape/Capacity).
        </DxItem>
      </DxBox>
      <hr/>
      <!-- <DxBox> -->
        <!-- <DxItem :ratio="1"> -->
          <!-- <DxButton class="requestBtn" text="Change Location" @click="openRequest('location')" /> -->
        <!-- </DxItem> -->
        <!-- <DxItem :ratio="1"> -->
          <!-- Request a location change. -->
        <!-- </DxItem> -->
      <!-- </DxBox> -->
      <!-- <hr/> -->
    </div>
  </DxDrawer>
</template>

<script>

import DxButton from 'devextreme-vue/button';
import { DxBox, DxItem } from 'devextreme-vue/box';
import DxDrawer from 'devextreme-vue/drawer';
import DxTextBox from 'devextreme-vue/text-box';
import DxDateBox from 'devextreme-vue/date-box';

export default {
  components: {
    DxButton,
    DxBox,
    DxItem,
    DxDrawer,
    DxTextBox,
    DxDateBox,
  },
  props: ['containerId', 'container'],
  created() { 
    this.loadPageData()
  },
  data() {
    return {
      // container: {},
      selectedRequest: "",
      reqInfo: "",
      drawerOpen: false,

      reqLevel: "", 
      reqLevelDate: "", 
      reqProduct: "", 
      reqName: "", 
      reqDownhole: "", 
      reqCapacity: "", 
      reqVol: ""
    };
  },
  computed: {
  },
  methods: {
    loadPageData() {
    },
    openRequest(req){
      this.selectedRequest = req;
      this.resetAllRequestInfo();
      this.drawerOpen = !this.drawerOpen;
    },
    closeRequest(){
      this.drawerOpen = !this.drawerOpen;
    },
    sendRequest(){
      let request = {
        containerId: this.container.containerId,
        containerName: this.container.containerName,
        requestType: this.selectedRequest,
        requestInfo: this.getAllRequestInfo()
      };
      //console.log(request);
      this.$store.dispatch('containers/sendActionRequest', request);
      this.$emit('recordUpdated');
      this.closeRequest();
    },
    resetAllRequestInfo(){
      this.reqLevel="";
      this.reqLevelDate= "";
      this.reqProduct= "";
      this.reqName= "";
      this.reqDownhole= "";
      this.reqCapacity= "";
      this.reqVol = "";
    },
    getAllRequestInfo(){
      return this.reqLevel + this.reqLevelDate + this.reqProduct + this.reqName + this.reqDownhole + this.reqCapacity + this.reqVol
    },
    infoChanged(e){
      if (e.element.id=='level')
        this.reqLevel = "<br>Level: " + e.value;
      else if (e.element.id=='levelDate')
        this.reqLevelDate = "<br>Level Date: " + e.value;
      else if (e.element.id=='product')
        this.reqProduct = "<br>Product: " + e.value;
      else if (e.element.id=='name')
        this.reqName = "<br>Name: " + e.value;
      else if (e.element.id=='downhole')
        this.reqDownhole = "<br>Downhole: " + e.value;
      else if (e.element.id=='capacity')
        this.reqCapacity = "<br>Capacity: " + e.value;
      
    },
  },
};

</script>

<style scoped>
  .divGroup {
    width: 90%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .propHeader {
    font-size: 16px;
    font-weight: 300;
    color: rgb(255,255,255,.7);
    margin-bottom: 6px;
    z-index: 20;
  }
  .propVal {
    font-size: 14px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .propThreshold {
    width: 80%;
    font-size: 12px;
    font-weight: 300;
    color: rgb(255,255,255,.3);
    margin-left: 10px;
    margin-bottom: 2px;
    z-index: 20;
    padding: 2px;
  }
  hr {
    height:1px;
    border:1px solid #444;
  }
  .buttons {
    width: 200px;
    margin: 0 auto;
    display: inline;
  }
  .saveBtn {  
    color: black;  
    background-color: rgb(255, 115, 0);  
    /* margin: 0 20px 20px 0; */
    margin: 0  10px auto;
    width: 100px;
    display: inline-block;
    /* float: left; */
  } 
  .cancelBtn {  
    color: black;  
    background-color: rgb(255, 255, 255,0.3);  
    /* margin: 0 20px 20px 0; */
    margin: 0 auto;
    width: 100px;
    display: inline-block;
    /* float: right; */
    /* display: flex; */
    /* justify-content: flex-end; */
  } 
  .buttons {
    width: 100px;
    margin: 0 auto;
  }
  .requestBtn {  
    /* color: rgb(65, 65, 65);   */
    color: black;  
    background-color: rgb(255, 115, 0);  
    margin: 0 20px 20px 0;
    /* border-color: rgb(100, 100, 100);
    border-radius: 6px; */
    /* padding: 20px; */
  } 
  .requestBtn.dx-state-hover {  
    /* color: black;   */
    background-color: rgb(223, 102, 4);  
  }  
</style>
