<template>
  <div>
    <DxBox :height="550" direction="row" width="100%" >
      <DxItem :ratio="1">
        <R3DetailsMenu @menu-change="menuSelectionChange"/>
      </DxItem>
      <DxItem :ratio="3">
        <!-- <DxScrollView :width="auto" :height="500" direction="both">   -->
        <!-- <DxScrollView>   -->
          <R3DetailsInfo v-if="currMenu==0" :containerId="containerId" :container="container" />
          <R3DetailsSpecifics v-if="currMenu==1" :containerId="containerId" :container="container"/>
          <R3DetailsAnalytics v-if="currMenu==2" :containerId="containerId" :container="container"/>
          <R3DetailsNotes v-if="currMenu==3" :containerId="containerId" :container="container"/>
          <R3DetailsRequests v-if="currMenu==4" :containerId="containerId" :container="container"/>
        <!-- </DxScrollView> -->
      </DxItem>
    </DxBox>
  </div>
  
</template>

<script>

import R3DetailsMenu from "@/components/container/container-details-menu.vue";
import R3DetailsInfo from "@/components/container/container-details-info.vue";
import R3DetailsAnalytics from "@/components/container/container-details-analytics.vue";
import R3DetailsSpecifics from "@/components/container/container-details-specifics.vue";
import R3DetailsNotes from "@/components/container/container-details-notes.vue";
import R3DetailsRequests from "@/components/container/container-details-requests.vue";
import {DxBox, DxItem} from 'devextreme-vue/box';
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  components: {
    R3DetailsMenu,
    R3DetailsInfo,
    R3DetailsSpecifics,
    R3DetailsAnalytics,
    R3DetailsNotes,
    R3DetailsRequests,
    DxBox,
    DxItem,
    // DxScrollView
  },
  created() {  
    this.currMenu = 0;
  },
  props: ['containerId', 'container'],
  data() {
    return {
      currMenu: 0
    };
  },
  watch: {
    async containerId (){
      this.currMenu = 0;
    },
  },
  methods: {
    menuSelectionChange(menu){
      this.currMenu = menu;
    }
  },
};

</script>

<style scoped>
  
</style>
