
import common from '../common.js';

export default {
  async loadNoteTypes(context, payload ) {
    var url = `notetypes`;
    const responseJsonType = await common.getRequest(context, payload, url)
    const responseDataType = [];
    for (const key in responseJsonType) 
    {
      const noteType = {
        noteTypeId: responseJsonType[key].noteTypeId,
        noteTypeName: responseJsonType[key].noteTypeName,
      };
      responseDataType.push(noteType);        
    }
    context.commit('setNoteTypes', responseDataType)
  },
}
