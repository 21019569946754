// store/modules/containers/getters.js
export default {
    // Existing container getters
    getContainer(state) {
        console.log("GET", state.container)
        return state.container;
    },
    getContainers(state) {
        return state.containers;
    },
    getContainersV2(state) {
        return state.containersV2;
    },
    gridContainers(state) {
        return state.gridContainers;
    },
    gridGridContainers(state) {
        return state.gridContainers;
    },
    hasGridContainers(state) {
        return state.gridContainers && state.gridContainers.length > 0;
    },
    selectedContainer(state) {
        return state.selectedContainer;
    },
    getContainerHistory(state) {
        return state.containerHistory;
    },
    getContainerHistoryDetail(state) {
        return state.containerHistoryDetail;
    },
    getContainerPropertyHistory(state) {
        return state.containerPropertyHistory;
    },
    getContainersStatus(state) {
        if (state.containersStatus == null) {
            return null;
        }
        if (state.containersStatusFilter == null) {
            return state.containersStatus;
        }
        return state.containersStatus.filter(item => 
            state.containersStatusFilter.includes(item.containerStatus)
        );
    },
    getContainersFlatProperties(state) {
        const ctrs = state.containersStatus;
        for (const key in ctrs) {
            for (const propKey in ctrs[key].properties) {
                let pname = ctrs[key].properties[propKey].propertyName; 
                ctrs[key][pname] = ctrs[key].properties[propKey].propValue;
            }
        }
        return ctrs;
    },
    getContainersFlatProperties_ViewOperator(state) {
        const ctrs = state.containersStatus;
        for (const key in ctrs) {
            for (const propKey in ctrs[key].properties) {
                let pname = ctrs[key].properties[propKey].propertyName; 
                if (
                    pname == "Product" ||
                    pname == "Downhole LSD" ||
                    pname == "Capacity" ||
                    pname == "DTE" ||
                    pname == "Volume (%)" ||
                    pname == "Injection Avg" ||
                    pname == "Injection Target" ||
                    pname == "Last Device Communication"            
                ) {
                    ctrs[key][pname] = ctrs[key].properties[propKey].propValue;
                }
            }
        }
        return ctrs;
    },
    getContainerStatus(state) {
        return state.containerStatus;
    },
    getContainersStatusFilter(state) {
        return state.containersStatusFilter;
    },
    getContainerProps(state) {
        return state.containerProps;
    },
    getContainerNotes(state) {
        return state.containerNotes;
    },
    getContainerExtendedHistory(state) {
        return state.containerExtendedHistory;
    },
    

    // Enhanced map-specific getters
    getMapContainers: (state) => {
        return state.containers.map(container => ({
            ...container,
            currentLevel: getContainerLevel(container),
            chemicalType: getContainerChemicalType(container),
            status: getContainerStatus(container)
        }));
    },

    getFilteredMapContainers: (state) => (filters) => {
        let filtered = state.containers;
        
        if (filters?.operator) {
            filtered = filtered.filter(c => c.operatorId === filters.operator);
        }
        
        if (filters?.area) {
            filtered = filtered.filter(c => c.areaId === filters.area);
        }
        
        if (filters?.chemical) {
            filtered = filtered.filter(c => getContainerChemicalType(c) === filters.chemical);
        }
        
        if (filters?.levelRange) {
            filtered = filtered.filter(c => {
                const level = getContainerLevel(c);
                return level >= filters.levelRange[0] && level <= filters.levelRange[1];
            });
        }
        
        return filtered.map(container => ({
            ...container,
            currentLevel: getContainerLevel(container),
            chemicalType: getContainerChemicalType(container),
            status: getContainerStatus(container)
        }));
    },

    getContainerLocation: (state) => (containerId) => {
        const container = state.containers.find(c => c.id === containerId);
        return container ? { lat: container.latitude, lng: container.longitude } : null;
    },

    getContainersInBounds: (state) => (bounds) => {
        return state.containers.filter(container => {
            const lat = container.latitude;
            const lng = container.longitude;
            return lat >= bounds.southWest.lat && 
                   lat <= bounds.northEast.lat && 
                   lng >= bounds.southWest.lng && 
                   lng <= bounds.northEast.lng;
        });
    },

    getContainersByStatus: (state) => (status) => {
        return state.containers.filter(container => getContainerStatus(container) === status);
    }
};

// Helper functions for container data transformation
const getContainerLevel = (container) => {
    const volumeProp = container.properties?.find(p => p.propertyName === "Volume (%)");
    return volumeProp ? parseFloat(volumeProp.propValue) : 0;
};

const getContainerChemicalType = (container) => {
    const productProp = container.properties?.find(p => p.propertyName === "Product");
    return productProp ? productProp.propValue : '';
};

const getContainerStatus = (container) => {
    const level = getContainerLevel(container);
    if (level <= 20) return 'critical';
    if (level <= 40) return 'warning';
    return 'normal';
};