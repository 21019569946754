<template>
  <R3Title title="Reporting ❯ Map"></R3Title>
  <!-- <div class="content-block dx-card responsive-paddings"> -->
  <div>
    <!-- <div class="ph" id="header">
      <h2 class="content-block">Reporting ❯ Tank Map</h2>
      <DxLoadPanel
        :visible="isLoadPanelVisible"
        :hide-on-outside-click="true"
        message="Loading..."
        :shadow="true"
      />
    </div> -->

    <div class="divGroup" id="filterDiv">
      <ControlPanel 
        :filtered-count="filteredContainers.length"
        :filtered-containers="filteredContainers"
        @operator-changed="onOperatorChanged"
        @area-changed="onAreaChanged"
        @products-changed="onProductsChanged"
        @volume-range-changed="onVolumeRangeChanged"
        @dte-range-changed="onDTERangeChanged"
      />
    </div>

    <div class="divGroup" id="mapDiv">
      <div class="map-wrapper">
        <l-map 
          v-if="showMap"
          :zoom="zoom"
          :center="center"
          :bounds="bounds"
          
          :options="mapOptions"
          ref="map"
          @ready="onMapReady"
        >
        <!-- :max-bounds="maxBounds" -->
        <!-- :center="center" -->
          <l-tile-layer 
            :url="`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=${store.state.config.mapbox.accessToken}`"
            :attribution="MAP_STYLES[1].options.attribution"
            :options="{
              tileSize: 512,
              zoomOffset: -1
            }"
          />
          <l-layer-group>
            <l-marker
              v-for="container in filteredContainers"
              :key="container.containerId"
              :lat-lng="getContainerLocation(container)"
              :icon="getContainerStatus(container)"
              :options="{
                riseOnHover: true,
                bubblingMouseEvents: false,
                autoPan: true,
                zIndexOffset: 1000
              }"
              @click="onMarkerClick(container)"
            >
            <l-popup>
              <ContainerDetails 
                :container="container"
                :simplified="true"
                @close="closePopup" 
              />
            </l-popup>
            </l-marker>
          </l-layer-group>
        </l-map>
      </div>
      <div class="details-wrapper">
        <ContainerDetails 
          v-if="store.getters['containers/selectedContainer']"
          :container="store.getters['containers/selectedContainer']" 
        />
      </div>
    </div>
  </div>
</template>

<script setup>

import R3Title from "@/components/base/page-title.vue";
import R3Subtitle from "@/components/base/sub-title.vue";

import 'leaflet/dist/leaflet.css'
import { ref, computed, onMounted, onUnmounted, watch, nextTick, reactive } from 'vue'
import { useStore } from 'vuex'
import { latLngBounds, latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LLayerGroup, fitBounds } from '@vue-leaflet/vue-leaflet'
import { DxLoadPanel } from 'devextreme-vue'
import { MAP_CONFIG, MAP_STYLES } from '@/config/mapConfig'
import { getContainerIcon } from '@/utils/leafletIconConfig'
import { getMarkerIcon, getMarkerIconThreshold } from '@/utils/leafletIconConfig'
import ControlPanel from './ControlPanel.vue'
import ContainerDetails from './ContainerDetails.vue'

const store = useStore()
const map = ref(null)
const isLoadPanelVisible = ref(true)
const showMap = ref(true)
// const zoom = ref(12)
// const center = ref([51.0447, -114.0719])
// const bounds = ref([
//       [40.712, -74.227],
//       [40.774, -74.125]
//     ])

const zoom = ref(6)
const center= ref([53.5951, -113.5729])
const bounds = ref(latLngBounds([
        [51.0447, -114.0719],
        [51.0447, -114.0719]
      ]))
const maxBounds = latLngBounds([
        [49.6418110285536, -125.32887335733673],
        [59.89389306893233, -102.05988041663947]
      ])

const mapOptions = ref({
  ...MAP_CONFIG.controls,
  preferCanvas: true,
  wheelDebounceTime: 150,
  wheelPxPerZoomLevel: 120,
  zoomSnap: 0.5,
  zoomDelta: 0.5,
  markerZoomAnimation: true
})



const containerData = ref([])
  const filters = reactive({
    operator: '',
    area: '',
    product: '',
    products: [],
    volumeRange: [0, 100],
    dteRange: [0, 10000]
  })

  const filteredContainers = computed(() => {
    if (!containerData.value) return []
  
    return containerData.value.filter(container => {
      // First check for valid coordinates
      const hasValidCoords = container.latitude !== 0 && container.longitude !== 0 
        && container.latitude != null && container.longitude != null
    
      if (!hasValidCoords) {
        // console.log('Dropping container with invalid coordinates:', container.containerId)
        return false
      }

      // Then apply other filters
      const matchesOperator = !filters.operator || container.operator === filters.operator
      const matchesArea = !filters.area || container.area === filters.area
      // const matchesProduct = !filters.product || container.properties?.find(p => 
      //   p.propertyName === 'Product' && p.propValue === filters.product
      // )
      const matchesProducts = !filters.products || filters.products===undefined || filters.products.length==0 || filters.products.includes(container.Product)
      const matchesVolume = !filters.volumeRange || (() => {
        const volumeProp = container.properties?.find(p => p.propertyName === 'Volume (%)')
        const volume = volumeProp ? parseFloat(volumeProp.propValue) : 0
        return volume >= filters.volumeRange[0] && volume <= filters.volumeRange[1]
      })()
      const matchesDTE = !filters.dteRange || (() => {
        const dteProp = container.properties?.find(p => p.propertyName === 'DTE')
        const dte = dteProp ? parseFloat(dteProp.propValue) : 0
        return dte >= filters.dteRange[0] && dte <= filters.dteRange[1]
      })()

      // return matchesOperator && matchesArea && matchesProduct && matchesVolume && matchesDTE
      return matchesOperator && matchesArea && matchesProducts && matchesVolume && matchesDTE
    })
  })
  // Enhanced filter handlers with logging
  const onOperatorChanged = (value) => {
    // console.log('Operator filter changing to:', value)
    filters.operator = value
    forceUpdate()
  }

  const onAreaChanged = (value) => {
    // console.log('Area filter changing to:', value)
    filters.area = value
    forceUpdate()
  }

  const onProductsChanged = (value) => {
    // console.log('Product(s) filter changed:', value)
    filters.products = value
    
    forceUpdate()
  }

  const onProductChanged = (value) => {
    // console.log('Product filter changing to:', value)
    filters.product = value
    forceUpdate()
  }

  const onVolumeRangeChanged = (value) => {
    // console.log('Volume range changing to:', value)
    filters.volumeRange = value
    forceUpdate()
  }
  
  const onDTERangeChanged = (value) => {
    // console.log('DTE range changing to:', value)
    filters.volumeRange = value
    forceUpdate()
  }

  const forceUpdate = () => {
    // Create a new reference to force reactivity
    const currentData = [...containerData.value]
    containerData.value = null
    nextTick(() => {
      containerData.value = currentData
      updateMapBounds()
    })
  }

  // Add a watch on the filtered results
  watch(() => filteredContainers.value, (newValue) => {
    // console.log('Filtered results updated:', newValue.length)
    nextTick(() => {
      updateMapBounds()
    })
  }, { deep: true })
      const getContainerLocation = (container) => {
    // console.log('Getting location for container:', {
    //   id: container.containerId,
    //   name: container.containerName,
    //   coords: {
    //     lat: container.latitude,
    //     lng: container.longitude
    //   }
    // })

    if (!container?.latitude || !container?.longitude) {
      console.log('No coordinates found for container:', container.containerId)
      return null
    }

    return [container.latitude, container.longitude]
}
const getContainerStatus = (container) => {
  // console.log("STATUS", container)
  const volumeProp = container.properties?.find(p => p.propertyName === "Volume (%)")
  const volume = volumeProp ? volumeProp.propValue : 0
  
  // return getMarkerIcon(volume)
  return getMarkerIconThreshold(container)
}

const onMarkerClick = (container) => {
  // console.log('Marker clicked, container:', container)
  store.commit('containers/setSelectedContainer', container)
  // console.log('Selected container in store:', store.getters['containers/selectedContainer'])
}

const closePopup = () => {
  store.commit('containers/setSelectedContainer', null)
}
const onMapReady = () => {
  updateMapBounds()
}

const updateMapBounds = () => {
  if (filteredContainers.value.length && map.value) {
    // const bounds = filteredContainers.value
    //   .map(c => getContainerLocation(c))
    //   .filter(Boolean)
    
    // if (bounds.length) {
    //   // map.value.fitBounds(bounds)  // JSC no such function
    // }

    //JSC
    var minLat = 999
    var maxLat = -999
    var minLong = 999
    var maxLong = -999
    
    for (let i = 0; i < filteredContainers.value.length; i++) { 
      var lat = parseFloat(filteredContainers.value[i].latitude)
      var long = parseFloat(filteredContainers.value[i].longitude)
      // console.log(`${lat}, ${long}`)
      if (lat < minLat){
        minLat = lat}
      if (lat > maxLat)
        maxLat = lat
      if (long < minLong && long > -130)
        minLong = long
      if (long > maxLong)
        maxLong = long
    }
    // console.log("CONTAINER BOUNDARIES")
    // console.log(`${minLat}, ${minLong} : ${maxLat}, ${maxLong}`)
    bounds.value = latLngBounds([
        [minLat-0.2, maxLong+0.2],
        [maxLat+0.2, minLong-0.2]
      ])
  }
}

const loadData = async () => {
  try {
    // isLoadPanelVisible.value = true
    console.log('Loading container data')
    
    // Load both data sources
    await Promise.all([
      store.dispatch('containers/loadContainers'),
      store.dispatch('containers/loadContainersStatus')
    ])

    // Get coordinates map
    const coordsData = store.state.containers.containers
    const coordsMap = new Map(
      coordsData.map(c => [c.containerId, { 
        latitude: c.latitude, 
        longitude: c.longitude 
      }])
    )

    // Get rich container data and merge with coordinates
    containerData.value = store.getters['containers/getContainersFlatProperties_ViewOperator']
      .map(container => ({
        ...container,
        ...coordsMap.get(container.containerId)
      }))

    console.log('Combined data:', {
      count: containerData.value?.length,
      sampleContainer: {
        id: containerData.value?.[0]?.containerId,
        coords: coordsMap.get(containerData.value?.[0]?.containerId)
      }
    })
  } finally {
    // isLoadPanelVisible.value = false
  }
}
onMounted(() => {
  loadData()
})

onUnmounted(() => {
  if (map.value) {
    map.value.remove()
  }
})
</script>

<style lang="scss">
@import "@/themes/generated/variables.base.scss";
@import "@/themes/generated/variables.additional.scss";

.map-wrapper {
  height: calc(100vh - 400px);
  min-height: 500px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  // background-color: var(--base-bg);
}

.details-wrapper {
  margin-top: 20px;
  border-radius: 4px;
  background-color: var(--base-bg);
}

.divGroup {
    width: 95%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
// @media screen and (max-width: $screen-xs-max) {
//   .map-wrapper {
//     height: calc(100vh - 500px);
//     min-height: 400px;
//   }
// }

</style>



