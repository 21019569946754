import common from '../common.js';

export default {
  async deleteUser(context, payload) {
    const newRequest = {
      userId: payload.userId,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/users/${payload.userId}`;
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    if (!response.ok) {
      const error = new Error('Failed to send request.');  
      throw error;
    }
    // context.commit('updateDistrict', payload);
  },
  
  async requestPasswordReset(context, payload) {
    
    const newRequest = {
      userName: payload.userName,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/authenticate/request-password`;
    
    console.log("ACTION REQUEST", newRequest);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    console.log("ACTION RESPONSE", response.status);

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');  
      throw error;
    }
  },

  async resetPassword(context, payload) {
    const newRequest = {
      userName: payload.userName,
      pw: payload.newPassword,
      pwResetId: payload.uid,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/authenticate/reset-password`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    //context.commit('updateUserPw', payload);
    
  },

  async updateProfile(context, payload) {
    
    var token = context.rootGetters['auth/getToken']
    
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
  
    const tokenDetails = JSON.parse(jsonPayload);

    var bearer_token = "Bearer " + token;
    
    var apiUrl = context.rootGetters['config/getApiUrl']
    //var url = `${apiUrl}/users/${tokenDetails.Id}/password`;
    var url = `${apiUrl}/users/profile/password`;

    const newRequest = {
      userId: tokenDetails.Id,
      pwHash: payload.pw
    };

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    context.commit('updateUserPw', payload);
    
  },

  async loadProfile(context, payload ) {
    var token = context.rootGetters['auth/getToken'];
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    // var url = `${apiUrl}/users/${id}`;  
    var url = `${apiUrl}/users/profile`;  

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error("Failed to fetch.");
      throw error;
    }

    const responseJson = await response.json();

    const user = {
      userId: responseJson.userId,
      customerId: responseJson.customerId,
      userStatusId: responseJson.userStatusId,
      userName: responseJson.userName,
      firstName: responseJson.firstName,
      surname: responseJson.surname,
      lastLogin: responseJson.lastLogin,
      dateJoined: responseJson.dateJoined,
      };
  
    context.commit('setProfile', user)

  },

  async loadUsers(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/users`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const user = {
        userId: responseJson[key].userId,
        customerId: responseJson[key].customerId,
        userStatusId: responseJson[key].userStatusId,
        userName: responseJson[key].userName,
        firstName: responseJson[key].firstName,
        surname: responseJson[key].surname,
        lastLogin: responseJson[key].lastLogin,
        dateJoined: responseJson[key].dateJoined,
        view: responseJson[key].defaultView,
        };

      responseData.push(user);
        
    }
    context.commit('setUsers', responseData)
  },

  async loadUser(context, payload ) {

    const id = payload.id;

    let user = {
      userId: 0,
      customerId: 0,
      userStatusId: 0,
      userName: "",
      firstName: "",
      surname: "",
      lastLogin: new Date().toLocaleString(),
      dateJoined: new Date().toLocaleString(),
      };

    if (id != 0)
    {
      var token = context.rootGetters['auth/getToken'];
      var bearer_token = "Bearer " + token;
      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/users/${id}`;  
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        //error, NOT ok
        const error = new Error("Failed to fetch.");
        throw error;
      }
  
      const responseJson = await response.json();
  
      user = {
        userId: responseJson.userId,
        customerId: responseJson.customerId,
        userStatusId: responseJson.userStatusId,
        userName: responseJson.userName,
        firstName: responseJson.firstName,
        surname: responseJson.surname,
        lastLogin: responseJson.lastLogin,
        dateJoined: responseJson.dateJoined,
        view: responseJson.defaultView,
        };
    }
    context.commit('setUser', user)
  },

  async loadUserGroups(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/usergroups`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      if (responseJson[key].userId == payload.id)
      {
        const usergroup = {
          userGroupId: responseJson[key].userGroupId,
          groupId: responseJson[key].groupId,
          userId: responseJson[key].userId,
        };
        responseData.push(usergroup);
      }
    }
    context.commit('setUserGroups', responseData)
  },

  async addUser(context, payload) {
    var d = new Date();
    // var datString =  d.getFullYear() + "-" + d.getDate()  + "-" + (d.getMonth()+1) + "T" + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
    var datString =  d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "T" + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    
    const newRequest = {
      userId: 0,
      userStatusId: 1,
      userName: payload.userName,
      firstName: payload.firstName,
      surname: payload.surname,
      pwHash: payload.pwHash,
      lastLogin: datString,
      dateJoined: datString,
      uiMetric: true,
      uiTimezone: 0,
      pwResetDate: "2000-01-01T00:00:00",
      pwResetId: crypto.randomUUID(),
      defaultView: payload.view
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/users`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    payload.userId = responseJson.userId;
    context.commit('addUser', payload);
  },

  async addUserGroup(context, payload) {
    const newRequest = {
      userGroupId: 0,
      groupId: parseInt(payload.groupId),
      userId: parseInt(payload.userId)
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/usergroups`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    payload.userGroupId = responseJson.userGroupId;
    context.commit('addUserGroup', payload);
  },

  async deleteUserGroup(context, payload) {
    const id = payload.userGroupId;

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/usergroups/${id}`;
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    // const responseJson = await response.json();
    // payload.userGroupId = responseJson.userGroupId;
    // context.commit('addUserGroup', payload);
  },
  async updateUser(context, payload) 
  {
    console.log(payload)

    const request = {
      userId: payload.userId,
      userName: payload.userName,
      firstName: payload.firstName,
      surname: payload.surname,
      pwHash: payload.pwHash,
      defaultView: payload.view
    };

    if (request.pwHash=="") delete request['pwHash'];
    
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/users/${payload.userId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request)
    });

    //no-content
    //const responseData = await response.json();
        
    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText
    
    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');  
      throw error;
    }
    
    // context.commit('updateUser', payload);
  },
};
