// src/config/mapConfig.js
import store from '@/store';

// Get token from store
const MAPBOX_ACCESS_TOKEN = store.state.config.mapbox.accessToken;

if (!MAPBOX_ACCESS_TOKEN) {
  console.error('Mapbox access token not found');
}

const DEFAULT_CENTER = store.state.config.mapbox.defaultCenter;
const DEFAULT_ZOOM = store.state.config.mapbox.defaultZoom;

export const MAP_STYLES = [
  {
    id: 'streets',
    name: 'Streets',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  },
  {
    id: 'satellite',
    name: 'Satellite',
    url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`,
    options: {
      tileSize: 512,
      zoomOffset: -1,
      attribution: '© Mapbox © OpenStreetMap'
    }
  },
  {
    id: 'dark',
    name: 'Dark',
    url: `https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`,
    options: {
      tileSize: 512,
      zoomOffset: -1,
      attribution: '© Mapbox © OpenStreetMap'
    }
  }
];

export const MAP_CONFIG = {
  accessToken: MAPBOX_ACCESS_TOKEN,
  defaultCenter: DEFAULT_CENTER,
  defaultZoom: DEFAULT_ZOOM,
  maxZoom: 18,
  minZoom: 3,
  clusterOptions: {
    disableClusteringAtZoom: 16,
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    maxClusterRadius: 40
  },
  controls: {
    zoomControl: true,
    attributionControl: true
  },
  theme: {
    baseColor: '#1a1a1a',
    textColor: '#ffffff',
    borderColor: '#404040'
  }
};

export const CONTAINER_STATUS_COLORS = {
  critical: '#ff4444',
  warning: '#ffbb33',
  normal: '#00C851',
  inactive: '#757575'
};

export const MAP_LAYER_OPTIONS = {
  tileSize: 512,
  zoomOffset: -1,
  crossOrigin: true
};

export { DEFAULT_CENTER, DEFAULT_ZOOM };
