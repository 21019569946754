<template>
  <R3Title title="Admin ❯ Containers"></R3Title> <!-- unicode U+276F : ❯ -->
  <!-- <R3Subtitle :title=currentContainer.containerName></R3Subtitle> -->

  <DxToolbar class="menubar">
    <DxItem
      :options="cancelButtonOptions"
      location="before"
      widget="dxButton"
    />
    <DxItem
      :options="deleteButtonOptions"
      location="before"
      widget="dxButton"
    />
    <DxItem
      :options="saveButtonOptions"
      location="before"
      widget="dxButton"
    />
  </DxToolbar>
  
  <div class="divGroup" v-if="currentContainer.containerTypeId==0">
    <DxSelectBox class="form-field" v-if="(this.currentRecordId==0)" label="Container Type Id" :items="this.containerTypes" value-expr="containerTypeId" display-expr="containerTypeName" v-model:value="currentContainer.containerTypeId" @value-changed="typeChanged()"/>     
  </div>
  <div class="divGroup" v-else>
    <DxTextBox class="form-field" label="Id" :read-only="true" :value="currentContainer.containerId" />
    <DxTextBox class="form-field" label="Name" v-model:value="currentContainer.containerName" @value-changed="nameChanged" /> 
    <DxSelectBox class="form-field" label="Container Type Id" :read-only="true" :data-source="this.containerTypes" value-expr="containerTypeId" display-expr="containerTypeName" v-model:value="currentContainer.containerTypeId"/>     
    <!-- <DxSelectBox class="form-field" v-if="(this.currentRecordId==0)" label="Container Type Id" :data-source="this.containerTypes" value-expr="containerTypeId" display-expr="containerTypeName" v-model:value="currentContainer.containerTypeId" @value-changed="typeChanged()"/>      -->
    <!-- <DxSelectBox class="form-field" v-if="(this.currentRecordId!=0)" label="Container Type Id" :read-only="true" :data-source="this.containerTypes" value-expr="containerTypeId" display-expr="containerTypeName" v-model:value="currentContainer.containerTypeId"/>      -->
    
    <DxSelectBox class="form-field" label="Division ID" :read-only="true" :data-source="this.divisions" value-expr="divisionId" display-expr="divisionName" v-model:value="currentContainer.divisionId"/>     
    <DxSelectBox class="form-field" label="Operator Id" :data-source="this.operators" value-expr="operatorId" display-expr="operatorName" v-model:value="currentContainer.operatorId" @value-changed="operatorChanged"/>     
    <DxCheckBox class="form-field" text="View All" :value="allAreas" :enable-three-state-behavior="false" @value-changed="allAreasChanged" />
    <DxSelectBox label="Area Id" v-if="allAreas==true" :data-source="this.areas" value-expr="areaId" display-expr="areaName" v-model:value="currentContainer.areaId"/>     
    <DxSelectBox label="Area Id" v-else :data-source="existingOperatorAreas" value-expr="areaId" display-expr="areaName" v-model:value="currentContainer.areaId"/>     
    <!-- <DxTextBox class="form-field" label="GPS Coordinates" :read-only="false" :data-source="getGPS" /> -->
    <DxTextBox class="form-field" label="Latitude" :read-only="false" v-model:value="currentContainer.latitude" />
    <DxTextBox class="form-field" label="Longitude" :read-only="false" v-model:value="currentContainer.longitude" />
    <DxTextBox class="form-field" v-if="(this.currentRecordId!=0)" label="Serial Number" ref="serial" :read-only="true" v-model:value="currentContainer.serialnum" />
    
    <div v-if="(this.currentRecordId==0)">
      <DxBox direction="row" width="100%">
        <DxItem :ratio="1" >
          <h6>Serial Numbers: </h6>
        </DxItem>
        <DxItem :ratio="2" >
          <DxTextBox class="divProp" label="Add Serial Number" v-model:value="newSerialNum" @value-changed="addSerial"/>
          <DxItem
            :options="cancelButtonOptions"
            location="after"
            widget="dxButton"
          />
        </DxItem>
        <DxItem :ratio="1" />
        <DxItem :ratio="1" />
      </DxBox>
      <DxList style="width: 20%;margin: 0 0 0 220px;"
        :data-source="containerDevices"
        allow-item-deleting="true"
        item-delete-mode="static"
        @item-deleting="serialDeleted"
      />
    </div>
  </div>

  <div class="divGroup" v-if="currentContainer.containerTypeId!=0">
    <div v-for="prop in properties.filter(x=>x.containerTypeId==currentContainer.containerTypeId && x.appliesTo=='C')" :key="prop.propertyId">
      <DxBox direction="row" width="100%">
        <DxItem :ratio="1" >
          <h6>{{prop.propertyName}} : </h6>
        </DxItem>
        <DxItem :ratio="2">
          <!-- <DxTextBox :ref="prop.propertyId" class="divProp" label="Value" :value="this.getContainerPropVal(prop.propertyId,'propValue')" @value-changed="(e)=> { this.propValueChanged(prop, 'VALUE', e); }" /> -->
          <DxTextBox class="divProp" label="Value" :value="this.getContainerPropVal(prop.propertyId,'propValue')" @value-changed="(e)=> { this.propValueChanged(prop, 'VALUE', e); }" />
        </DxItem>
        <DxItem :ratio="1">
          <DxTextBox class="divProp" v-if="prop.useThresholds" label="Marginal Threshold" :read-only="!prop.useThresholds" :value="this.getContainerPropVal(prop.propertyId,'valueMarginal')" @value-changed="(e)=> { this.propValueChanged(prop, 'MARGINAL', e); }" />
        </DxItem>
        <DxItem :ratio="1">
          <DxTextBox class="divProp" v-if="prop.useThresholds" label="Critical Threshold" :read-only="!prop.useThresholds" :value="this.getContainerPropVal(prop.propertyId,'valueCritical')" @value-changed="(e)=> { this.propValueChanged(prop, 'CRITICAL', e); }"/>
        </DxItem>
      </DxBox>
    </div>
  </div>

</template>

<script>

import R3Title from "@/components/base/page-title.vue";
import R3Subtitle from "@/components/base/sub-title.vue";
import DxToolbar from 'devextreme-vue/toolbar';
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from 'devextreme-vue/select-box';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxBox, DxItem } from 'devextreme-vue/box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import DxList from 'devextreme-vue/list';

export default {
  components: {
    DxBox, 
    DxItem,
    DxToolbar,
    DxTextBox,
    DxSelectBox,
    DxCheckBox,
    // DxLoadPanel,
    R3Title,
    // R3Subtitle,
    DxList,
    
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      deleteButtonOptions: {
        icon: 'close',
        onClick: () => {
          // decommission site
          let result = confirm("<b>Are you sure you want to decommission this tank?</b>" + 
            "<p>Name: " + this.currentContainer.containerName + 
              "<br>Device: " + this.currentContainer.serialnum + 
              "<br>Operator: " + this.currentContainer.operator + 
              "<br>Area: " + this.currentContainer.area + 
              "<p><i>This cannot be restored.</i>", "Decommission?");
          result.then((dialogResult) => {this.decommission(dialogResult);});
        },
      },
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          this.saveContainerInfo();
          this.$emit('recordUpdated');
        },
      },
      currentContainer: "",
      operators: [],
      areas:[],
      containerTypes:[],
      divisions:[],
      allAreas: false,
      currentContainerProps: [],
      properties: [],
      loadingVisible: false,
      newSerialNum: null,
      changedProperties: [],
    };
  },
  computed: {
    currentRecordId() {
      return parseInt(this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1)); 
    },
    existingOperatorAreas(){
      return this.$store.getters["operators/getOperatorsAreas"].filter(x => x.operatorId == this.currentContainer.operatorId);
    },
    containerDevices(){
      if (this.currentContainer)
      {
        return this.currentContainer.serialnum.split(";;");
      } 
      else 
      {
        return "";
      }
    }
    // currentContainer(){
    //   return this.$store.getters["containers/getContainer"];
    // },
    // currentContainerProps(){
    //   return this.$store.getters["containers/getContainerProps"];
    // },
    // operators(){
    //   return this.$store.getters["operators/getOperators"];
    // },
    // areas(){
    //   return this.$store.getters["areas/getAreas"];
    // },
    // containerTypes(){
    //   return this.$store.getters["containerTypes/getContainerTypes"];
    // },
    // divisions(){
    //   return this.$store.getters["divisions/getDivisions"];
    // },
    // properties(){
    //   return this.$store.getters["properties/getProperties"];
    // },
  },
  methods: {
    typeChanged(){
      // reset all properties, keys, values and thresholds.  for new containers set up the properties object(s)
      let props = this.properties.filter(x=>x.containerTypeId==this.currentContainer.containerTypeId && x.appliesTo=='C')
      this.currentContainerProps=[];
      for(let i=0; i<props.length; i++)
      {
        let newTypeProp = {
          containerPropId: 0,
          containerId: this.currentRecordId,
          propertyId: props[i].propertyId,
          propValue: "",
          marginal: "",
          critical: "",
          propertySort: ""
        }
        this.currentContainerProps.push(newTypeProp);
      }   
    },
    getGPS(){
      return this.currentContainer.longitude + ', ' + this.currentContainer.latitude;
    },
    addSerial(){
      if(this.newSerialNum.trim().length>0)
      {
        this.currentContainer.serialnum += ';;'+ this.newSerialNum;
      }
      this.newSerialNum="";
      // console.log(this.newSerialNum);
    },
    serialDeleted (e) {
      const itemData = e.itemData;
      const itemDomNode = e.itemElement;
      const itemIndex = e.itemIndex;
      
      this.currentContainer.serialnum= this.currentContainer.serialnum.replace(e.itemData,'');
      this.currentContainer.serialnum= this.currentContainer.serialnum.replace(';;;;',';;');
      if (this.currentContainer.serialnum.lastIndexOf(';;', 0)==0) 
        this.currentContainer.serialnum = this.currentContainer.serialnum.substring(2);
      if (this.currentContainer.serialnum.endsWith(';;'))
        this.currentContainer.serialnum = this.currentContainer.serialnum.substring(0, this.currentContainer.serialnum.length-2);
    },
    onLoadPanelShown() {
      setTimeout(() => { this.loadingVisible = false; }, 10000);
    },
    onLoadPanelHidden() {
      // 
    },    
    getContainerPropVal(propId, returnCol){
      let ret = "";
      if (this.currentContainerProps.filter(x => x.propertyId == propId).length > 0)
      {
        ret = this.currentContainerProps.filter(x => x.propertyId == propId)[0][returnCol];
      }
      return ret;
    },
    nameChanged(e){
      this.currentContainer.containerName = e.value;
    },
    operatorChanged(e) {
      if (e.previousValue!==undefined && e.previousValue!==null )
        this.currentContainer.areaId = null;
    },
    allAreasChanged(e) {
      this.allAreas = e.value;
      this.currentContainer.areaId = 0;
    },
    async saveContainerInfo(){
      console.log("SAVE");
      console.log(this.currentContainer);
      
      if (this.currentRecordId==0) //new record
      {
        let props = []
        for(let i = 0; i < this.changedProperties.length; i++)
        {
          let prop = {
            propertyId: this.changedProperties[i].propertyId,
            propValue: this.changedProperties[i].propValue,
            marginal: this.changedProperties[i].marginal,
            critical: this.changedProperties[i].critical,
          }
          props.push(prop);
        }

        const newSite= {
          containerName: this.currentContainer.containerName.trim(),
          containerTypeId: this.currentContainer.containerTypeId,
          deviceSerial: this.currentContainer.serialnum.trim(),
          operatorId: this.currentContainer.operatorId,
          areaId: this.currentContainer.areaId,
          properties: props,
        }
        
        console.log(newSite);

        await this.$store.dispatch('utils/addSite', newSite);
        this.$emit('recordUpdated');
        this.result = await this.$store.getters["utils/getAddSiteResults"];
        if (this.result.recordsAffected != 0)
        {
          this.newResponse = "Record created. (" + this.result.recordsAffected + ")\n" + new Date().toISOString() + "\n" + this.result.message;
          // this.setProperties(this.result.recordsAffected);
        }
        else
        {
          this.newResponse = "Record not created. " + new Date().toISOString() + "\n" + this.result.message.replaceAll(';','\r\n');
          return;
        }
      }
      else //ie preexisting site
      {
        let request={
          containerId: this.currentRecordId,
          containerTypeId: this.currentContainer.containerTypeId,
          operatorId: this.currentContainer.operatorId,
          divisionId: this.currentContainer.divisionId,
          areaId: this.currentContainer.areaId,
          containerName: String(this.currentContainer.containerName).trim(),
          serialnum: String(this.currentContainer.serialnum).trim(),
          latitude: this.currentContainer.latitude,
          longitude: this.currentContainer.longitude,
          altitude: this.currentContainer.altitude
        }
        await this.$store.dispatch('containers/updateContainer', request);
        //SAVE PROPERTIES
        await this.$store.dispatch('containers/updateContainerProperties', this.changedProperties);
      }
      
    },
    propValueChanged(prop, field, data) {
      let containerProp = this.currentContainerProps.find(x=>x.propertyId===prop.propertyId)
      let changeProp = this.changedProperties.find(x=>x.propertyId===prop.propertyId);
      
      if (changeProp==null)
      { //if the property hasn't already been changed once and is in the changedProperties collection
        changeProp={
          containerPropId: containerProp.containerPropId,
          containerId: this.currentRecordId,
          propertyId: prop.propertyId,
          propValue: prop.propValue,
          marginal: prop.valueMarginal,
          critical: prop.valueCritical,
          propertySort: prop.propertySort
        };
        this.changedProperties.push(changeProp);
      }

      if(field=="VALUE")
        { changeProp.propValue = data.value; }
      else if(field=="MARGINAL")
        { changeProp.marginal=data.value; }
      else if(field=="CRITICAL")
        { changeProp.critical=data.value; }
      console.log("CURRENT PROPS", this.currentContainerProps);
      console.log("CHANGED", this.changedProperties);
    },
    popNotification(msg, type){
      //types ['error', 'info', 'success', 'warning']
      const position = 'center';
      const direction = 'down-stack';
      notify(
        {
          message: msg,
          type: type
        },
        { 
          position, 
          direction 
        }
      );
    },
    decommission(confirmed){
      if (!confirmed)
      { 
        this.popNotification('Container decommission cancelled.','warning');
        return; 
      }

      const site = {
        containerName: this.currentContainer.containerName,
        deviceSerial: this.currentContainer.serialnum,
      }

      this.$store.dispatch('utils/decommissionSite', site);
      this.$emit('recordUpdated');
      this.result = this.$store.getters["utils/getDecommissionSiteResults"];
      if (this.result.recordsAffected==-1)
      { this.popNotification("Site decommissioned successfully. " + Date().toLocaleString() + "\n" + this.result.message,'success'); }
      else
      { this.popNotification("ERROR: Site not decommissioned. " + Date().toLocaleString() + "\n" + this.result.message,'error'); }
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      this.loadingVisible = true;
      try {
        // console.log("ID",this.currentRecordId);
           
        await this.$store.dispatch("operators/loadOperators");
        this.operators = await this.$store.getters["operators/getOperators"];
        console.log("OPERS", this.operators);
        await this.$store.dispatch("operators/loadOperatorsAreas");
        await this.$store.dispatch("areas/loadAreas");
        this.areas = await this.$store.getters["areas/getAreas"];
        console.log("AREAS", this.areas);
        await this.$store.dispatch("containerTypes/loadContainerTypes");
        this.containerTypes = await this.$store.getters["containerTypes/getContainerTypes"];
        console.log("CONT-TYPES", this.containerTypes);
        await this.$store.dispatch("divisions/loadDivisions");     
        this.divisions = await this.$store.getters["divisions/getDivisions"];
        console.log("DIV", this.divisions);
        await this.$store.dispatch("properties/loadProperties");        
        this.properties = await this.$store.getters["properties/getProperties"];
        console.log("PROPS", this.properties);
        await this.$store.dispatch("containers/loadContainer", {id: this.currentRecordId} );
        this.currentContainer = await this.$store.getters["containers/getContainer"];
        console.log("CONTAINER", this.currentContainer);        
        await this.$store.dispatch("containers/loadContainerProperties", {id: this.currentRecordId} );
        this.currentContainerProps= await this.$store.getters["containers/getContainerProps"];
        console.log("CONTAINER-PROPS", this.currentContainerProps);
        
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }   
      this.loadingVisible = false;
    },
  },
};
</script>

<style scoped>
  .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
  }
  .propName {
    width: 25%;
    margin: 10px;
    text-align: right;
    /* line-height: 75px; */
    /* font-size: 30px; */
  }
  .propVal {
    width: 35%;
    margin: 10px;
    text-align: left;
    /* line-height: 75px; */
    /* font-size: 30px; */
  }
  .propValWide {
    width: 75%;
    margin: 10px;
    text-align: left;
    /* line-height: 75px; */
    /* font-size: 30px; */
  }
  .propThresh {
    width: 20%;
    margin: 10px;
    text-align: left;
    /* line-height: 75px; */
    /* font-size: 30px; */
  }
  .form-field{
    margin: 30px 0 0 0;
  }
  .tb{
    margin: 10px 0 0 2px;
  }
  .menubar{
    padding: 0 60px 0 40px ;
  }
  h6 {
    margin: 30px 0 0 0;
    font-size: 14px;
    font-weight: lighter;
    /* text-align: right; */
  }
  .divGroup {
    width: 95%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .divProp {
    width: 95%;
    margin: 10px;
  }
</style>
