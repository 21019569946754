<template>
  
  <div>
    <DxMap
      :zoom="8"
      :height="300"
      v-model:type="roadmap"
      v-model:markers="markers"
      center="52.2668, -113.802"
      width="100%"
      provider="bing"
      api-key="Aq3LKP2BOmzWY47TZoT1YdieypN_rB6RY9FqBfx-MDCKjvvWBbT68R51xwbL-AqC"
    />
  </div>
  <!-- center="52.2668, -113.802" -->
  
</template>

<script>

import DxMap from 'devextreme-vue/map';

export default {
  components: {
    DxMap
  },
  props: ['containerId', 'container'],
  data() {
    return {
      
    };
  },
  created() {  },
  computed: { 
    markers(){
      if (!this.container)
      { return [] ; }

      let marks = [];
      let mark = {
        location: [this.container.latitude, this.container.longitude],
        tooltip: { text: this.container.containerName}}
      marks.push(mark);
      return marks;
    }
  },
  methods: {

  },
};

</script>

<style scoped>
  .pageContent {
    /* max-height: 40vh; */
    /* overflow: scroll; */
  }
  .ph { /* page header */
    height: 50px;
  } 
  img {
    width: 80px;  
    height: 80px;
  } 
  p {
    text-align: left;  
    margin-left:4em; 
    margin-top:0em;
    font-weight: 300;
  }
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
  .prop{
    margin: 10px 60px 0 60px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  .mininote {
    text-align: left;  
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
</style>
