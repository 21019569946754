<template>
  <div class="content-block" ref="footer">
    <footer class="footer">
      Powered by Remote.io ; Copyright © 2022-{{new Date().getFullYear()}} ; All rights reserved.
    </footer>
  </div>
</template>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.footer {
  display: block;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0px;
  // padding-top: 20px;
  padding-bottom: 5px;
  // padding-bottom: 24px;
  font-size:12px;
}
</style>
