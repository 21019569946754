export  default {
  setContainers(state, payload) {
    state.containers = payload;
  },
  setContainersV2(state, payload) {
    state.containersV2 = payload;
  },
  setContainer(state, payload) {
    // console.log("SET CONTAINER", payload);
    state.container = payload;
  },
  setGridContainers(state, payload) {
    state.gridContainers = payload;
  },
  setSelectedContainer(state, payload) {
    state.selectedContainer = payload;
  },
  setContainerHistory(state, payload) {
    state.containerHistory = payload;
  },
  setContainerHistoryDetail(state, payload) {
    state.containerHistoryDetail = payload;
  },
  setContainerPropertyHistory(state, payload) {
    state.containerPropertyHistory = payload;
  },
  setContainersStatus(state, payload) {
    state.containersStatus = payload;
  },
  setContainerStatus(state, payload) {
    state.containerStatus = payload;
  },
  setContainerProps(state, payload) {
    state.containerProps = payload;
  },
  updateContainerProperties(state, payload) {
    
  },
  setContainersStatusFilter(state, payload) {
    state.containersStatusFilter = payload;
  },
  setMapContainers(state, containers) {
    state.mapContainers = containers
  },
  
  updateContainerLocation(state, { containerId, latitude, longitude }) {
    // Update in main containers array
    const container = state.containers.find(c => c.containerId === containerId)
    if (container) {
      container.latitude = latitude
      container.longitude = longitude
    }
    // Update in map containers array
    const mapContainer = state.mapContainers.find(c => c.id === containerId)
    if (mapContainer) {
      mapContainer.position = { lat: latitude, lng: longitude }
    }
  },
  updateMapContainerStatus(state, { containerId, status }) {
    const container = state.mapContainers.find(c => c.id === containerId)
    if (container) {
      container.status = status
    }
  },
  setMapFilters(state, filters) {
    state.mapFilters = { ...state.mapFilters, ...filters }
  }, 
  setContainerNotes(state, payload) {
    state.containerNotes = payload;
  },
  addContainerNote(state, payload) {
    state.containerNotes.push(payload);
  },
  setResponseCode(state, payload) {
    state.responseCode = payload;
  },
  setContainerHistoryExtended(state, payload) {
    state.containerExtendedHistory = payload;
  },
};