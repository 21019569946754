<template>
  <div class="details-panel" v-if="container">
    <div class="ph">
      <!-- <h2 class="content-block"> -->
      <h2>
        {{ container.containerName }}
        <!-- <button @click="$emit('close')" class="close-button">×</button> -->
      </h2>
    </div>

    <div v-if="simplified" class="popup-content">
      <div class="details-section">
        <div class="pheader">Operator</div>
        <div class="pval">{{ getContainerVal(container.operator) }}</div>
        <div class="pheader">Volume (%)</div>
        <div class="pval">{{ getPropertyVal('Volume (%)') }}</div>
        <div class="pheader">Product</div>
        <div class="pval">{{ getPropertyVal('Product') }}</div>
      </div>
    </div>

    <div v-else class="details-grid">
      <div class="details-section">
        <div class="pheader">Downhole LSD</div>
        <div class="pval">{{ getPropertyVal('Downhole LSD') }}</div>
      </div>
      
      <div class="details-section">
        <div class="pheader">Operator</div>
        <div class="pval">{{ getContainerVal(container.operator) }}</div>
      </div>
      
      <div class="details-section">
        <div class="pheader">Area</div>
        <div class="pval">{{ getContainerVal(container.area) }}</div>
      </div>
      
      <div class="details-section">
        <div class="pheader">Device</div>
        <div class="pval">{{ getContainerVal(container.serialnum) }}</div>
      </div>

      <div class="details-section">
        <div class="pheader">Product</div>
        <div class="pval">{{ getPropertyVal('Product') }}</div>
      </div>
      
      <div class="details-section">
        <div class="pheader">Volume (%)</div>
        <div class="pval">{{ getPropertyVal('Volume (%)') }}</div>
      </div>

      <div class="details-section">
        <div class="pheader">Volume (L)</div>
        <div class="pval">{{ getPropertyVal('Volume (L)') }}</div>
      </div>
      
      <div class="details-section">
        <div class="pheader">Capacity</div>
        <div class="pval">{{ getPropertyVal('Capacity') }}</div>
      </div>

      <div class="details-section">
        <div class="pheader">Injection Avg</div>
        <div class="pval">{{ getPropertyVal('Injection Avg') }}</div>
      </div>
      
      <div class="details-section">
        <div class="pheader">Injection Target</div>
        <div class="pval">{{ getPropertyVal('Injection Target') }}</div>
      </div>
      
      <div class="details-section">
        <div class="pheader">Days To Empty</div>
        <div class="pval">{{ getPropertyVal('DTE') }}</div>
      </div>
    </div>

    <div class="chart-section" v-if="showHistory">
      <DxChart :data-source="containerHistory">
        <DxCommonSeriesSettings
          argument-field="historyDate"
          value-field="propValue"
          type="line"
        />
        <DxValueAxis :visual-range="[0, 80]">
          <DxStrip :start-value="0" :end-value="15" color="#FF000010" />
        </DxValueAxis>
        <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
      </DxChart>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue'
import { DxChart, DxCommonSeriesSettings, DxValueAxis, DxStrip, DxTooltip } from 'devextreme-vue/chart'

const props = defineProps({
  container: {
    type: Object,
    required: true
  },
  simplified: {
    type: Boolean,
    default: false
  }
})

const showHistory = ref(false)

const containerHistory = computed(() => {
  return props.container.history?.map(h => ({
    historyDate: new Date(h.date).toLocaleDateString(),
    propValue: parseFloat(h.value)
  })) || []
})

const getPropertyVal = (propName) => {
  const prop = props.container.properties?.find(p => p.propertyName === propName)
  return prop?.propValue || '-'
}

const getContainerVal = (value) => {
  return value || '-'
}

const customizeTooltip = (pointInfo) => {
  return {
    text: `${pointInfo.argumentText}: ${pointInfo.valueText}%`
  }
}
</script>

<style lang="scss" scoped>
@import "@/themes/generated/variables.base.scss";

.details-panel {
  width: 100%;
  background-color: var(--base-bg);
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  padding: 15px;
}

.details-section {
  padding: 15px;
  border-radius: 4px;
  // background-color: rgba($base-accent, 0.05);
  background-color: rgba(255,255,255, 0.15);
}

.chart-section {
  margin-top: 20px;
  padding: 15px;
}

.close-button {
  background: none;
  border: none;
  color: rgba($base-text-color, 0.4);
  font-size: 24px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  
  &:hover {
    background-color: rgba($base-text-color, 0.1);
    color: $base-text-color;
  }
}

.pheader {
  font-size: 14px;
  color: rgba($base-text-color, 0.7);
  margin-bottom: 4px;
}

.pval {
  font-size: 16px;
  font-weight: 500;
}
</style>