<template>
  <!-- unicode U+276F : ❯ -->
  <h2 class="content-block">Reporting ❯ Tank Consumption</h2>

  <DxToolbar class="menubar">
    <!-- <DxToolbarItem :options="cancelButtonOptions" location="before" widget="dxButton"/> -->
    <!-- <DxToolbarItem :options="detailButtonOptions" location="after" widget="dxButton"/> -->
    <!-- <DxToolbarItem location="after" template="filterStatusCriticalTemplate"/> -->
    <!-- <DxButton icon="detailslayout" @click="gotoDetail" hint="Details"/> -->
  </DxToolbar>

  <div class="content-block dx-card responsive-paddings">
    <div class="fld">
      <DxDateBox 
        label="Start Date"
        labelMode="floating"
        openOnFieldClick="true"
        v-model:value="startDate"
        type="date"
        :max="endDate"
        :min="minDate"
      />
    </div>
    <div class="fld">
      <DxDateBox 
        label="End Date"
        labelMode="floating"
        openOnFieldClick="true"
        v-model:value="endDate"
        type="date"
        :min="startDate"
      />
    </div>
    <div class="fld"> 
      <DxSelectBox 
        label="Operator"
        :data-source="operators" 
        display-expr="operatorName"
        value-expr="operatorName" 
        v-model:value="operator"
        />
    </div>
    <div class="fld">
      <DxButton
        text="Run Report"
        type="default"
        @click="runReport"
      />
    </div>  
  </div>

  <div class="content-block dx-card responsive-paddings" id ="dataDiv">
    <DxDataGrid
      class="dx-card wide-card"
      :data-source="usageData"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      :columns-auto-width="true"
      :column-min-width="50"
      :hover-state-enabled="true"
      :key-expr="containerId"
      :filter-builder="filterBuilder"
      :filter-value="filterValue"
      :column-resizing-mode="nextColumn"
      :allow-column-resizing="true"
      style="max-height: 80vh"
      >
    
      <DxExport :enabled="true" />
      <DxColumnChooser :enabled="true" mode="select" />
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxColumn data-field="containerId" :visible="false"/>
      <DxColumn data-field="containerName" caption="Surface" :visible="true"/>
      <DxColumn data-field="downholeLSD" :visible="true"/>
      <DxColumn data-field="alias" :visible="true"/>
      <DxColumn data-field="operatorName" :visible="true"/>
      <DxColumn data-field="areaName" :visible="true"/>
      <DxColumn data-field="product" :visible="true"/>
      <DxColumn data-field="startDate" :visible="true"/>
      <DxColumn data-field="endDate" :visible="true"/>
      <DxColumn data-field="volumeStart" :visible="true"/>
      <DxColumn data-field="volumeEnd" :visible="true"/>
      <DxColumn data-field="volumeFill" :visible="true"/>
      <DxColumn data-field="notes" :visible="true"/>
    </DxDataGrid>
  </div>

  <DxLoadPanel
    :position="{ of: '#dataDiv' }"
    v-model:visible="loadingVisible"
    :message="Loading"
    :show-indicator="true"
    :show-pane="true"
    :shading="true"
    :hide-on-outside-click="false"
    :on-shown="onShown"
    :on-hidden="onHidden"
    shading-color="rgba(0,0,0,0.4)"
  />
</template>

<script>
import {DxToolbar} from 'devextreme-vue/toolbar';
import {DxExport} from "devextreme-vue/chart";
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import { stringifyQuery } from 'vue-router';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  components: {
  DxToolbar,
  //   DxToolbarItem,
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxButton,
  DxSelectBox,
  DxDateBox,
  DxExport,
  DxLoadPanel
},
  created() {
    this.loadPageData();
  },
  data() {
    return {
      minDate: new Date(2020, 0, 1),
      // endDate: new Date(),
      endDate: null,
      // startDate: new Date(2023, 0, 1),
      startDate: null,
      operator: "",
      usageData: null,
      operators: null,
      loadingVisible: false,
    };
  },
  methods: {
    async runReport(){
      let err = '';
      if (this.startDate > this.endDate) {
        err += "Start date must be less than or equal to end date\n";
      }
      if (this.startDate == null) {
        err += "Start date must not be null\n";
      }
      if (this.endDate == null) {
        err += "End date must not be null\n";
      }
      if (this.operator == null) {
        err += "Operator must not be null\n";
      }
      // if (this.area == null) {
      //   err += "Area must not be null\n";
      // }
      if (err.length > 0) {
        console.log(err);
       return;}

      this.loadingVisible = true;
      const request = {
        operatorName : this.operator,
        startDate : this.startDate,
        endDate : this.endDate,
      }

      try {
          await this.$store.dispatch("usage/loadUsage", request);
          this.usageData = this.$store.getters["usage/getUsage"];
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      } 
      this.loadingVisible = false;
      
      
    },
    async loadPageData(refresh = false) {
      this.minDate = new Date();
      this.minDate.setDate(this.minDate.getDate() - 90);

      try {
          await this.$store.dispatch("operators/loadOperators");
          this.operators = await this.$store.getters["operators/getOperators"];
    } catch (error) {
      this.error = error.message || "Unexpected Error.";
    } 
    },
  },
};
</script>

<style scoped>
.fld{
  margin: 20px 0 0 0;
}
.menubar{
  padding: 0 60px 0 60px ;
}
</style>
