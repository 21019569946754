<template>
  <div>
    <DxBox :height="50" direction="row" width="100%">
      <DxItem :ratio="5">
          <div style="font-size:22px">Volume History Daily</div>
      </DxItem>
      <DxItem :ratio="0" :base-size="300">
        <DxButtonGroup
          :items="rangeGroup"
          :selected-item-keys="['30']"
          key-expr="range"
          styling-mode="outline"
          @item-click="rangeDaysChange"
        />
      </DxItem>
      <DxItem :ratio="0" :base-size="60">
        <div>Details</div>
        <DxSwitch v-model:value="volViewDetail" @value-changed="changeVolView" label="Hourly"/>
      </DxItem>
    </DxBox>
  </div>
  <div v-if="volViewDetail">  
    <DxChart class="chartGroup" :data-source="containerVolumeHistoryDetail" >
      <DxCommonSeriesSettings
        argument-field="historyDate"
        value-field="propValue"
        type="line"
        show-in-legend="false"
        color="#9090FF"
      />
      <DxSize :height="400" />
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="bottom"
        visible="true"
      />
      <DxSeriesTemplate name-field="containerName" visible="false" />
      <DxExport :enabled="true" />
      <!-- <DxTitle text="Volume History Hourly" /> -->
      <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
      <DxValueAxis show-zero="true" />  
    </DxChart>
  </div>
  <div v-else>
    <div id="graphVolExportBtn" >
      <DxChart class="chartGroup" :data-source="containerVolumeHistory" >
        <DxCommonSeriesSettings
          argument-field="historyDate"
          value-field="propValue"
          type="line"
          show-in-legend="false"
          color="#9090FF"
        />
        <DxSize :height="400" />
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
          item-text-position="bottom"
          visible="true"
        />
        <DxSeriesTemplate name-field="containerName" visible="false" />
        <!-- <DxExport :enabled="true" /> -->
        <!-- <DxTitle text="Volume History Daily" /> -->
        <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
        <DxValueAxis show-zero="true" />  
      </DxChart>
    </div>
  </div>  
</template>

<script>

import { DxChart, 
  DxSize,
  DxCommonSeriesSettings,
  DxSeriesTemplate,
  DxExport,
  DxLegend,
  // DxTitle,
  DxSubtitle,
  DxTooltip,
  DxValueAxis,
  // DxSeries,
  // DxZoomAndPan,
  // DxStrip
} from "devextreme-vue/chart";
import { DxSwitch } from 'devextreme-vue/switch';
import DxButtonGroup from 'devextreme-vue/button-group';
import { DxBox, DxItem } from 'devextreme-vue/box';

export default {
  components: {
    DxChart, 
    DxSize,
    DxCommonSeriesSettings,
    DxSeriesTemplate,
    DxLegend,
    // DxTitle,
    // DxSubtitle,
    DxTooltip,
    DxValueAxis,
    // DxSeries,
    DxExport,
    // DxStrip,
    DxSwitch,
    DxButtonGroup,
    DxBox,
    DxItem,
  },
  props: ['containerId', 'container'],
  created() { 
    this.loadHistory();
  },
  data() {
    return {
      volViewDetail: false ,
      volViewLbl: "Summary",
      rangeDays: 30,
      rangeGroup : [
        {
          range: '14',
          text: '14 days'
        },
        {
          range: '30',
          text: '30'
        },
        {
          range: '60',
          text: '60'
        },
        {
          range: '90',
          text: '90'
        },
      ],
    };
  },
  computed: {
    containerVolumeHistory(){
      const currentHistoryType = this.rangeDays;
      var data = this.$store.getters["containers/getContainerHistory"];
      
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);

      for (const key in data) {
        if (data[key].propertyName==="Volume (L)" && data[key].historyDate>=dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }
      return volumeHist;
    }, 

    containerVolumeHistoryDetail(){
      const currentHistoryType = this.rangeDays
      var data = this.$store.getters["containers/getContainerHistoryDetail"];
      
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);
      
      for (const key in data) {
        if (data[key].propertyName==="Volume (L)" && data[key].historyDate >= dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate,
            // historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2) + " " + data[key].historyDate.getDate().slice(-8),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }
      return volumeHist;
    }, 
  },
  // watch: {
  //   containerId: function (newId, oldId) {
  //     this.loadHistory();
  //   }
  // },
  methods: {
    async loadHistory(){
      this.volViewLbl = "Summary";
      this.volViewDetail = false;

      // if (this.container.containerId != null && this.container.containerId !== undefined && this.container.containerId > 0)
      // {
      //   await this.$store.dispatch("containers/loadContainerHistory", this.container.containerId);
      //   await this.$store.dispatch("containers/loadContainerHistoryDetail", this.container.containerId);
      // }
    },
    customizeTooltip(pointInfo) {
      return {
        html: `
          <div>
            <div style="width: 100px;padding:5px;text-align: center;"><span style="font-size:18px;font-weight:bold;">${pointInfo.value} L</span></div>
            <div style="width: 100px;padding:5px;text-align: center;">${pointInfo.argumentText}</div>
          </div>`
      };
    },
    changeVolView(e) {
      this.volViewLbl=e.value? "Detail" : "Summary";
    },
    rangeDaysChange(e) {
      this.rangeDays = e.itemData.range
    }
  },
};

</script>

<style scoped>
  .divGroup {
    width: 90%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .propHeader {
    font-size: 16px;
    font-weight: 300;
    color: rgb(255,255,255,.4);
    margin-bottom: 6px;
    z-index: 20;
  }
  .propVal {
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .propThreshold {
    font-size: 12px;
    font-weight: 300;
    color: rgb(255,255,255,.3);
    margin-left: 10px;
    margin-bottom: 2px;
    z-index: 20;
  }
  .hr {
    height:1px;
    border:1px solid #444;
  }
  .chartGroup {
    /* width: 85%; */
    height: 300px;
  }
</style>
