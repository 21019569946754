import common from '../common.js';

export default {
  async loadUserState(context, payload ) 
  {
    const id = payload.id;
    let state = {
      userstateId: 0,
      userId: 0,
      stateKey: "",
      stateName: "",
      stateValue: "", 
    };
    if (id)
    {
      const responseJson = await common.getRequest(context, payload, `userState/${id}`)
      // console.log(`${responseJson} - loadUserState`)
      state = {
        userstateId: responseJson.userstateId,
        userId: responseJson.userId,
        stateKey: responseJson.stateKey,
        stateName: responseJson.stateName,
        stateValue: responseJson.stateValue, 
      };
    }
    context.commit('setUserState', state)
  },
  async addUserState(context, payload) {
    const newRequest = {
      userstateId: 0,
      userId: 0,
      stateKey: payload.name,
      stateName: payload.name,
      stateValue: payload.stateValue,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/userstate`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }
    
    // const responseJson = await response.json();
  },
};
